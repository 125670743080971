import React from "react";
import Joi from "joi-browser";
import { ProgressBar, Alert } from "react-bootstrap";
import * as userServices from "../../services/userServices";
import { uploadHandler } from "../../services/donationServices";
import Form from "../common/form";
import Popup from "../common/popup";
import states from "./state";
import "./signup.scss";
import ImageAfterUpload from "./../common/ImgAfterUpload";

class Signup extends Form {
  state = {
    progress: 0,
    showAlert: false,
    data: {
      name: "",
      phone: "",
      email: "",
      password: "",
      contact_person: "",
      c_number: "",
      address: "",
      zipcode: "",
      city: "",
      state: "",
      account_type: "farm",
      user_type: "donor",
      cuisine: "",
      question: "",
      answer: "",
      addPhoto: "",
    },
    errors: [],
    value: 2,
  };

  schema = {
    name: Joi.string().required().label("Name"),
    phone: Joi.string().required().label("Phone"),
    contact_person: Joi.string().label("Contact Person").allow(""),
    c_number: Joi.string().allow(""),
    address: Joi.string().required().label("Address"),
    zipcode: Joi.string().required().label("Zip code"),
    city: Joi.string().required().label("City"),
    state: Joi.string().label("State").allow(""),
    account_type: Joi.string().label("Type of Donor").allow(""),
    user_type: Joi.string().required().label("User Type"),
    cuisine: Joi.string().label("Cuisine").allow(""),
    question: Joi.string().required().label("Question"),
    answer: Joi.string().required().label("Answer"),

    email: Joi.string().email({
      minDomainSegments: 2,
      tlds: { allow: ["com", "net"] },
    }),
    password: Joi.string().required().label("Password"),
    user_type: Joi.string().required().label("User Category"),
    addPhoto: Joi.string().label("addPhoto").allow(""),
  };

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  doSubmit = async () => {
    try {
      const response = await userServices.register(this.state.data);

      if ((response.status = 200)) {
        // window.alert("Data Registered");
        // window.location.href = "/login";
        this.setState({ showAlert: true });
      }
    } catch (ex) {
      // const errors = { ...this.state.errors };
      let errors = [];
      if (ex.response) errors.push(ex.response.data.message);

      this.setState({ errors });
    }
  };

  handleCategoryChange = (e) => {
    const user_type = e.target.value;
    const { data } = this.state;
    data.user_type = user_type;
    this.setState({ data });
  };

  handleStateChange = (e) => {
    const address_state = e.target.value;
    const { data } = this.state;
    data.state = address_state;
    this.setState({ data });
  };

  handleAccountCategoryChange = (e) => {
    const account_type = e.target.value;
    const { data } = this.state;
    data.account_type = account_type;
    this.setState({ data });
  };

  handleCuisineChange = (e) => {
    const cuisine = e.target.value;
    const { data } = this.state;
    data.cuisine = cuisine;
    this.setState({ data });
  };

  handleQuestionsChange = (e) => {
    const question = e.target.value;
    const { data } = this.state;
    data.question = question;
    this.setState({ data });
  };

  displayErrors = () => {
    return this.state.errors.map((error) => {
      return <p key={error}>{error}</p>;
    });
  };

  onChangePhoto = async (inputValue, inputField) => {
    let image = null;
    let { data, progress } = { ...this.state };
    const errors = { ...this.state.errors };
    if (inputField == "addPhoto" && inputValue) {
      this.setState({ progress: 50 });
      const response = await uploadHandler(inputValue);
      inputValue = response?.data?.fileName;
      if (response?.data?.fileName) progress = 100;
    }
    data[inputField] = inputValue;
    this.setState({ data, errors, progress });
  };

  removePicture = () => {
    this.setState({ progress: 0 });
    let state = { ...this.state };
    state.data.addPhoto = "";
    this.textInput.current.value = "";
    this.setState({ state });
  };

  render() {
    const { progress, data } = { ...this.state };
    const { showAlert } = this.state;

    return (
      <React.Fragment>
        <div className="wrapper">
          <div className="row">
            <div className="col-md-12 mt-20">
              {showAlert && (
                <Alert variant="success" dismissible>
                  <Alert.Heading>
                    A verification link has been sent to your email, please
                    click the link to continue.
                  </Alert.Heading>
                </Alert>
              )}
            </div>
          </div>
          <div className="center">
            <form
              onSubmit={this.handleSubmit}
              style={{ "margin-top": "20px" }}
              className="signup-form box-style-1"
            >
              <h1 className="text-center">Signup</h1>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Type of User</label>
                    <select
                      name="user_type"
                      className="form-control"
                      value={this.state.data.user_type}
                      onChange={this.handleCategoryChange}
                    >
                      <option value="donor">Donor</option>
                      <option value="charity">Charity</option>
                      <option value="volunteer">Volunteer</option>
                    </select>
                  </div>
                  <div>{this.renderInput("name", "Name")}</div>
                  <div>{this.renderInput("phone", "Phone")}</div>
                  <div>{this.renderInput("email", "Email")}</div>
                  <div>
                    {this.renderInput("password", "Password", "password")}
                  </div>
                  <div
                    className={
                      this.state.data.user_type == "volunteer" ? "d-none" : ""
                    }
                  >
                    {this.renderInput("contact_person", "Contact Person")}
                  </div>
                  <div
                    className={
                      this.state.data.user_type == "charity" ? "" : "d-none"
                    }
                  >
                    {this.renderInput("c_number", "500 c 3 Number")}
                  </div>
                  <div>{this.renderInput("address", "Address")}</div>
                </div>
                <div className="col-md-6">
                  <div>{this.renderInput("city", "City")}</div>
                  {/* <div>{this.renderInput("state", "State")}</div> */}
                  <div className="form-group">
                    <label>State</label>
                    <select
                      name="state"
                      className="form-control"
                      value={this.state.data.state}
                      onChange={this.handleStateChange}
                    >
                      <option value="">please select state </option>
                      {states.map((state) => (
                        <option key={state} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>{this.renderInput("zipcode", "Zip code")}</div>
                  <div
                    className={
                      this.state.data.user_type == "donor"
                        ? "form-group"
                        : "form-group d-none"
                    }
                  >
                    <label>Type of Donor</label>
                    <select
                      name="user_type"
                      className="form-control"
                      value={this.state.data.account_type}
                      onChange={this.handleAccountCategoryChange}
                    >
                      <option value="Farm">Farm</option>
                      <option value="Grocery">Grocery</option>
                      <option value="Other">Other</option>
                      <option value="Restaurant">Restaurant</option>
                    </select>
                  </div>
                  <div
                    className={
                      this.state.data.user_type == "donor"
                        ? "form-group"
                        : "form-group d-none"
                    }
                  >
                    <label>Cuisine</label>
                    <select
                      name="cuisine"
                      className="form-control"
                      value={this.state.data.cuisine}
                      onChange={this.handleCuisineChange}
                    >
                      <option value="">Cuisine</option>
                      <option value="American">American</option>
                      <option value="Bakery">Bakery</option>
                      <option value="Coffee Shop">Coffee Shop</option>
                      <option value="French">French</option>
                      <option value="Greek">Greek</option>
                      <option value="Indian">Indian</option>
                      <option value="Italian">Italian</option>
                      <option value="Japanese">Japanese</option>
                      <option value="Mexican">Mexican</option>
                      <option value="Middle Eastern">Middle Eastern</option>
                      <option value="Thai">Thai</option>
                      <option value="Coffee Shop">Other</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Security Question</label>
                    <select
                      name="question"
                      className="form-control"
                      value={this.state.data.question}
                      onChange={this.handleQuestionsChange}
                    >
                      <option value="">Security Question</option>
                      <option value="What is your best friend name from childhood?">
                        What is your best friend name from childhood?
                      </option>
                      <option value="What was your first school?">
                        What was your first school?
                      </option>
                      <option value="Which city were you born?">
                        Which city were you born?
                      </option>
                    </select>
                  </div>
                  <div className="form-group">
                    {this.renderInput("answer", "Answer")}
                  </div>
                  <div className="form-group">
                    <span onClick={this.removePicture} className="close">
                      <i
                        class="fa fa-times-circle text-danger"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <label>Upload profile picture</label>
                    <div>
                      <input
                        type="file"
                        ref={this.textInput}
                        onChange={(e) =>
                          this.onChangePhoto(e.target.files[0], "addPhoto")
                        }
                        name="addPhoto"
                        id="profilePic"
                        label="Add Photo"
                      />

                      <div style={{ width: "20%", marginTop: "5px" }}>
                        <ProgressBar now={progress} label={`${progress}%`} />
                      </div>
                      {data.addPhoto && (
                        <ImageAfterUpload fileName={data.addPhoto} />
                      )}
                    </div>
                  </div>

                  {this.state.errors.length > 0 && (
                    <Popup
                      title="Error"
                      body={<div>{this.displayErrors()}</div>}
                    />
                  )}
                  <button
                    type="submit"
                    className="btn btn-primary pull-right mt-10"
                  >
                    Signup
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Signup;
