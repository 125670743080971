import React, { Component } from "react";
import { Form, Button, ProgressBar } from "react-bootstrap";
import Popup from "../common/popup";
import ImgAfterUpload from "../common/ImgAfterUpload";
import { validate } from "../../validators/joiValidator";
import { uploadHandler } from "../../services/donationServices";

class EditItemPopup extends Component {
  state = {
    progress: 0,
    close: false,
    data: {
      editItemdata: {
        addFood: undefined,
        quantity: undefined,
        addPhoto: undefined,
      },
    },
    errors: {
      editItemErrors: {
        addFood: undefined,
        quantity: undefined,
        addPhoto: undefined,
      },
    },
  };

  editItemSchema = { ...this.props.editItemSchema };

  resetEditItemPopup = () => {
    const { data, errors, progress } = { ...this.state };
    data.editItemdata = { ...this.props.food };
    for (let key in errors.editItemErrors)
      errors.editItemErrors[key] = undefined;
    this.setState({ data, close: false, progress: 0 });
  };

  onDataChange = async (inputValue, inputField) => {
    const { data, errors } = { ...this.state };
    let { progress } = { ...this.state };
    const { editItemdata } = data;
    const { editItemErrors } = errors;
    if (inputField == "addPhoto" && inputValue) {
      this.setState({ progress: 50 });
      const response = await uploadHandler(inputValue);
      inputValue = response?.data?.fileName;
      progress = 100;
    }
    editItemdata[inputField] = inputValue;
    const validateData = {
      [inputField]: editItemdata[inputField],
    };
    const validateSchema = {
      [inputField]: this.editItemSchema[inputField],
    };

    const error = validate(validateSchema, validateData);
    editItemErrors[inputField] = error[0];
    data["editItemdata"] = editItemdata;
    errors["editItemErrors"] = editItemErrors;
    this.setState({ data, errors, progress });
  };

  ErrorsInEditItem = () => {
    const errors = validate(this.editItemSchema, {
      ...this.state.data.editItemdata,
    });
    if (errors.length > 0) return true;
    return false;
  };

  onSubmit = (e, editItemdata) => {
    this.props.editFoodItem(e, editItemdata);
    this.setState({ close: true });
  };

  render(props) {
    const { data, errors, close, progress } = { ...this.state };
    const food = { ...this.props.food };
    const { editItemdata } = data;
    const { editItemErrors } = errors;
    return (
      <Popup
        title="editFood"
        close={close}
        body={
          <Form onSubmit={(e) => this.onSubmit(e, editItemdata)}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Add Food</Form.Label>
              <Form.Control
                type="text"
                placeholder="Add Food"
                value={editItemdata.addFood}
                onChange={(e) => this.onDataChange(e.target.value, "addFood")}
              />
              {editItemErrors.addFood && (
                <div className="alert alert-danger mt-10 ">
                  {editItemErrors.addFood}
                </div>
              )}
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="text"
                placeholder="Quantity"
                value={editItemdata.quantity}
                onChange={(e) => this.onDataChange(e.target.value, "quantity")}
              />
              {editItemErrors.quantity && (
                <div className="alert alert-danger mt-10 ">
                  {editItemErrors.quantity}
                </div>
              )}
            </Form.Group>

            <Form.Group>
              <Form.File
                onChange={(e) =>
                  this.onDataChange(e.target.files[0], "addPhoto")
                }
                id="exampleFormControlFile1"
                label="Add Photo"
                name="upload_cont_img"
              />
              {
                <div style={{ width: "20%", marginTop: "5px" }}>
                  <ProgressBar now={progress} label={`${progress}%`} />
                </div>
              }
              {data.editItemdata.addPhoto && (
                <ImgAfterUpload fileName={data.editItemdata.addPhoto} />
              )}
            </Form.Group>
            <Button
              disabled={this.ErrorsInEditItem()}
              variant="primary"
              type="submit"
            >
              Save
            </Button>
          </Form>
        }
        buttonTrigger={
          <i
            onClick={this.resetEditItemPopup}
            className="fa fa-edit mt-3 pull-right"
          ></i>
        }
      />
    );
  }
}

export default EditItemPopup;
