import React, { Component, useState } from "react";
import Joi from "joi-browser";
import { Form } from "react-bootstrap";
import * as UserService from "../../services/userServices";
import Popup from "../common/popup";
import { validate } from "../../validators/joiValidator.js";
import { Redirect, withRouter } from "react-router-dom";

class UpdateUserPopUp extends Component {
  state = {
    close: false,
    editUser: {
      username: "",
      email: "",
      phone: "",
      is_principle_user: "",
    },
    errors: {
      username: "",
      email: "",
      phone: "",
      is_principle_user: "",
    },
  };

  AddItemSchema = {
    username: Joi.string().min(5).max(15).required().label("Name"),
    email: Joi.string().min(5).max(25).email().required(),
    phone: Joi.allow("", null),
    is_principle_user: Joi.boolean().required(),
  };

  componentDidMount = () => {
    this.resetData();
  };

  /** gets called when there is a change in form field in the popup */
  onEditChange = (ex, inputField) => {
    const { errors, editUser: data } = { ...this.state };

    if (inputField === "is_principle_user")
      data[inputField] = !data[inputField];
    else data[inputField] = ex.target.value;

    const validateData = {
      [inputField]: data[inputField],
    };
    const validateSchema = {
      [inputField]: this.AddItemSchema[inputField],
    };
    const error = validate(validateSchema, validateData);
    errors[inputField] = error[0];

    this.setState({ editUser: data, errors });
  };
  /**resets the state - data and errors when the edit icon is pressed. */
  resetData = () => {
    let { editUser, errors, close } = { ...this.state };
    close = false;
    editUser = { ...this.props.editUserData };
    for (let key in errors) errors[key] = "";
    this.setState({ close, editUser, errors });
  };

  /** gets the data and add the userId from props and call the backend service to update the user.  */
  updateUser = async (e) => {
    e.preventDefault();
    const userData = { ...this.state.editUser };
    userData["user_id"] = this.props?.editUserData?.user_id;
    const response = await UserService.updateUser(userData);
    if (response === "success") {
      this.setState({ close: true });
      this.props.loadUsers();
    }
  };

  render(props) {
    const { errors, editUser } = { ...this.state };
    return (
      <Popup
        title="Update User"
        body={
          <Form onSubmit={async (e) => await this.updateUser(e)}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={editUser.username}
                placeholder="Name"
                onChange={(ex) => this.onEditChange(ex, "username")}
              />
              {errors.username && (
                <div className="alert alert-danger mt-10 ">
                  {errors.username}
                </div>
              )}{" "}
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                value={editUser.email}
                placeholder="Email"
                onChange={(ex) => this.onEditChange(ex, "email")}
              />
              {errors.email && (
                <div className="alert alert-danger mt-10 ">{errors.email}</div>
              )}
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                value={editUser.phone}
                placeholder="Phone"
                onChange={(ex) => this.onEditChange(ex, "phone")}
              />
              {errors.phone && (
                <div className="alert alert-danger mt-10 ">{errors.phone}</div>
              )}
            </Form.Group>
            <div className="row p-10">
              <div className="col-10">Designate as Principal User</div>
              <div className="col-2 pr-0">
                <Form.Check
                  className="pull-right"
                  type="switch"
                  id="is_principle_user"
                  checked={editUser.is_principle_user ? true : false}
                  onChange={(ex) => this.onEditChange(ex, "is_principle_user")}
                />
              </div>
            </div>
            {errors.is_principle_user && (
              <div className="alert alert-danger mt-10 ">
                {errors.is_principle_user}
              </div>
            )}
            <div>
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </div>
          </Form>
        }
        buttonTrigger={
          <i
            onClick={() => this.resetData()}
            className="fa fa-edit mt-3 pull-right"
          />
        }
        close={this.state.close}
      />
    );
  }
}

export default UpdateUserPopUp;
