import React, { Component } from "react";
import _ from "lodash";
import avatar from "../../images/avatar.png";
import { Form } from "react-bootstrap";
import Popup from "../common/popup";
import Joi from "joi-browser";
import { validate } from "../../validators/joiValidator.js";
import plus from "../../images/plus.png";
import "./settings.scss";
import http from "../../services/httpService";
import * as userService from "../../services/userServices";
import UpdateUserPopUp from "../UpdateUserPopup/updateUserPopup";
import { getUserType } from "./../../services/userServices";

class Settings extends Component {
  state = {
    show: false,
    message: null,
    close: false,
    users: [],
    data: {
      username: "",
      email: "",
      phone: "",
      is_principle_user: false,
      miles: null,
      is_notification_enabled: false,
    },
    errors: {
      username: "",
      email: "",
      phone: "",
      is_principle_user: "",
      miles: "",
    },
  };

  AddItemSchema = {
    username: Joi.string().min(5).max(15).required().label("Name"),
    email: Joi.string().min(5).max(50).email().required(),
    phone: Joi.allow("", null),
    is_principle_user: Joi.boolean().required(),
    miles: Joi.number().allow(null),
    is_notification_enabled: Joi.allow("", null),
  };

  componentDidMount = async () => {
    const data = { ...this.state.data };
    data.miles = userService.userDetails?.notification_range;
    const is_notification_enabled =
      userService.userDetails?.is_notification_enabled;
    data.is_notification_enabled = is_notification_enabled;
    this.setState({ data });
    await this.loadAllUsers();
  };

  loadAllUsers = async () => {
    const getUsers = await userService.getAllUsers();
    const users = [];
    for (let user of getUsers) {
      users.push(
        _.pick(user, [
          "username",
          "email",
          "phone",
          "is_principle_user",
          "user_id",
        ])
      );
    }
    this.setState({ users });
  };

  onChange = (ex, inputField) => {
    const { data, errors } = this.state;

    if (
      inputField === "is_principle_user" ||
      inputField === "is_notification_enabled"
    )
      data[inputField] = !data[inputField];
    else data[inputField] = ex.target.value;

    const validateData = {
      [inputField]: data[inputField],
    };
    const validateSchema = {
      [inputField]: this.AddItemSchema[inputField],
    };
    const error = validate(validateSchema, validateData);
    errors[inputField] = error[0];
    this.setState({ data, errors });
  };

  handleDeleteUser = async (userId) => {
    const response = await userService.DeleteUser(userId);
    if (response === "success") {
      await this.loadAllUsers();
    }
  };
  loadEditUser = (userId) => {
    const { editUser, users } = { ...this.state };
    const user = users.filter((user) => user.user_id === userId);
    this.setState({ editUser: user[0] });
  };

  displayUsersAdded = () => {
    const users = [...this.state.users];
    const { editErrors: errors, editUser } = { ...this.state };
    return users.map((user) => (
      <div
        key={user.username}
        className="media mt-10 ml-10"
        style={{ alignItems: "center" }}
      >
        <img
          className="mr-2 avatar"
          src={avatar}
          alt="Generic placeholder image"
        />
        <div className="media-body row">
          <div className="col-8">
            <h5 className="mt-0 mb-0">{user.username}</h5>
            <span className="text-muted">{user.phone}</span>
          </div>

          <div className="col-4 hii">
            <i
              onClick={() => this.handleDeleteUser(user.user_id)}
              className="fa fa-trash mt-3 pull-right"
            ></i>
            <UpdateUserPopUp
              editUserData={user}
              {...this.props}
              loadUsers={() => this.loadAllUsers()}
            />
          </div>
        </div>
      </div>
    ));
  };

  addUser = async (e) => {
    e.preventDefault();
    const responseStatus = await userService.AddNewUser({ ...this.state.data });
    if (responseStatus === "success") {
      this.setState({ close: true });
      await this.loadAllUsers();
    }
  };

  updateSettings = async () => {
    const { miles, is_notification_enabled } = { ...this.state.data };
    const userinfo = userService.userDetails;
    const response = await userService.updateUserProfileSettings(
      miles,
      is_notification_enabled
    );
    if (response.status === "success") {
      userService.updateUserLocalSettings(miles, is_notification_enabled);
      this.setState({ show: true, message: "Settings updated Successfully!" });
    }
  };

  handleNotification = (e) => {
    console.log(e.target.value);
  };

  render() {
    const { data, errors, show, message } = this.state;
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const email = userDetails?.email;
    const username = userService.getDisplayName();
    const icon = userService.getUserDisplayIcon();
    return (
      <React.Fragment>
        {show && (
          <Popup
            size="sm"
            body={
              <>
                <h5>{message}</h5>
                <button
                  className="btn btn-sm pull-right btn-primary"
                  onClick={() => window.location.reload()}
                >
                  Ok
                </button>
              </>
            }
          />
        )}
        <div className="row" id="settings">
          <div className="col">
            <div className="title-1 row mt-20">
              <div className="col-12">Settings</div>
            </div>
            <hr className="line-1" />
          </div>
          <div className="col-md-12 box-style-2">
            <div className="media p-10">
              <img src={icon ? icon : avatar} className="avatar mr-2" />
              <div className="media-body row">
                <div className="col-8">
                  <h5 className="mt-0 mb-0">{username}</h5>
                  <span className="text-muted">{email}</span>
                </div>
              </div>
            </div>
          </div>
          {userService.getUserType() !== "donor" && (
            <div className="col-md-12 box-style-2 mt-20">
              <div className="row p-10">
                <div className="col-10" style={{ alignSelf: "center" }}>
                  Set miles to get notification
                </div>
                <div className="col-2">
                  <Form.Control
                    type="number"
                    value={data.miles}
                    onChange={(ex) => this.onChange(ex, "miles")}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="col-md-12 box-style-2 mt-20">
            <div className="row p-10">
              <div className="col-10">Enable Mobile Notifications</div>
              <div className="col-2 pr-0">
                <Form.Check
                  onChange={(e) => this.onChange(e, "is_notification_enabled")}
                  className="pull-right"
                  type="switch"
                  id="custom-switch"
                  checked={data.is_notification_enabled ? true : false}
                />
              </div>
            </div>
          </div>
          {userService.userDetails.is_principle_user && (
            <div className="col-md-12 box-style-2 mt-20">
              <div className="row">
                <div className="col-10 mb-20 ml-10 mt-10">
                  <h4>Add additional users</h4>
                </div>
              </div>
              {/* This part displays the list of Users added in the Settings page */}
              {this.displayUsersAdded()}
              {/* End of part  */}

              <div className="row mt-20 ml-10">
                <Popup
                  title="Add User"
                  body={
                    <Form onSubmit={(e) => this.addUser(e)}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          onChange={(ex) => this.onChange(ex, "username")}
                        />
                        {errors.username && (
                          <div className="alert alert-danger mt-10 ">
                            {errors.username}
                          </div>
                        )}
                      </Form.Group>

                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Email"
                          onChange={(ex) => this.onChange(ex, "email")}
                        />
                        {errors.email && (
                          <div className="alert alert-danger mt-10 ">
                            {errors.email}
                          </div>
                        )}
                      </Form.Group>

                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Phone"
                          onChange={(ex) => this.onChange(ex, "phone")}
                        />
                        {errors.phone && (
                          <div className="alert alert-danger mt-10 ">
                            {errors.phone}
                          </div>
                        )}
                      </Form.Group>
                      <div className="row p-10">
                        <div className="col-10">
                          Designate as principal user
                        </div>
                        <div className="col-2 pr-0">
                          <Form.Check
                            className="pull-right"
                            type="switch"
                            id="is_principle_user"
                            checked={data.is_principle_user ? true : false}
                            onChange={(ex) =>
                              this.onChange(ex, "is_principle_user")
                            }
                          />
                        </div>
                        <table
                          class="col-12 mt-3 mb-3"
                          border="1"
                          cellPadding="5"
                        >
                          <tr>
                            <th colspan="3">principal user</th>
                          </tr>
                          <tr>
                            <td>Yes</td>
                            <td>
                              Can edit profile information and add other users
                            </td>
                          </tr>
                          <tr>
                            <td>No</td>
                            <td>Does not have any admin functions</td>
                          </tr>
                        </table>
                      </div>
                      {errors.is_principle_user && (
                        <div className="alert alert-danger mt-10 ">
                          {errors.is_principle_user}
                        </div>
                      )}
                      <div>
                        <button type="submit" className="btn btn-primary">
                          Add
                        </button>
                      </div>
                    </Form>
                  }
                  buttonTrigger={
                    <React.Fragment>
                      <div style={{ margin: 10 }}>
                        <img
                          className="mr-2 avatar"
                          src={plus}
                          alt="add-image"
                        />
                        Add User
                      </div>
                    </React.Fragment>
                  }
                  close={this.state.close}
                />
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-12 pr-0">
            <button
              onClick={() => this.updateSettings()}
              className="btn btn-primary float-right mt-10"
            >
              Update Settings
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Settings;
