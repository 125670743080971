import { imageURL } from "../../config.json";
import "./imageAfterUpload.scss";

const ImageAfterUpload = (props) => {
  return (
    <img
      className="imageAfterUpload"
      src={imageURL + props.fileName}
      alt={props.fileName}
    />
  );
};

export default ImageAfterUpload;
