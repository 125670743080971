import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/auth/login";
const tokenKey = "token";

export async function login(email, password) {
  const { data: jwt } = await http.post(apiEndpoint, { email, password });
  if (jwt.token) {
    localStorage.setItem(tokenKey, jwt.token);
    localStorage.setItem("userDetails", JSON.stringify(jwt));
  }
  return jwt;
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem("UserDetails");
}

export const passwordReset = async (email) => {
  const apiEndPoint = apiUrl + "/auth/reset-password";
  const response = await http.post(apiEndPoint, {
    email,
  });
  return response;
};

export default {
  login,
  logout,
  passwordReset,
};
