import React from "react";
import icons from "./../common/icons";
import "./aboutWnf.scss";
import logo from "../../images/logo.png";
import support from "../../images/SupportWNF.svg";
import facebook from "../../images/SocialMediaIcons/Facebook.png";
import instagram from "../../images/SocialMediaIcons/Instagram.png";
import message from "../../images/SocialMediaIcons/MessageDarkIcon.png";
import twitter from "../../images/SocialMediaIcons/Twitter.png";
import { fbUrl, twitterUrl, instaUrl, mailId, aboutUsUrl } from "../constants";

const AboutWnf = () => {
  return (
    <div id="aboutWNF">
      <div className="container-wnf">
        <h3>About WNF</h3>
        <div className="image mt-20">
          <img
            style={{ height: "80px", width: "80px" }}
            src={logo}
            alt="logo"
          />
          <br />
          <br />
          <h2>Waste No Food</h2>
        </div>
        <div className="content mt-20 mb-20">
          <p style={{ textAlign: "left" }}>
            Waste No Food is a registered nonprofit that provides a web based
            “marketplace” allowing excess food to be donated from the food
            service industry to qualified charities that work with the needy.
            One-third of all food is wasted,from farms,restaurants and grocery
            stores.Yet there has been no efficient way for groups that have
            excess food to donate to the millions of hungry Californians and
            Americans.Waste No Food is that medium. Donors who wish to donate
            their excess food can sign up and post excess food as it becomes
            available.Qualified charities in the vicinity of the donors who have
            been vetted by Waste No Food, can claim the food. The charities are
            responsible for transportation and food handling.
          </p>
          <img src={support} alt="support in paypal" />
        </div>
        <div className="useful-links">
          <a target="_blank" href={aboutUsUrl}>
            Know more about us in official website
          </a>
          <a target="_blank" href={aboutUsUrl}>
            Food Donor Partners
          </a>
          <a target="_blank" href={aboutUsUrl}>
            Charity Partners
          </a>
        </div>
        <div className="social-media text-center">
          <a target="_blank" href="#">
            <img src={message} alt="Message" />
          </a>
          <a target="_blank" href={fbUrl}>
            <img src={facebook} alt="Facebook" />
          </a>
          <a target="_blank" href={twitterUrl}>
            <img src={twitter} alt="Twitter" />
          </a>
          <a target="_blank" href={instaUrl}>
            <img src={instagram} alt="Instagram" />
          </a>
        </div>
        <div className="Follow mb-20">
          <strong> Waste No Food</strong> <br />
          150 Farm Road,Woodside,CA 94062
        </div>
      </div>
    </div>
  );
};

export default AboutWnf;
