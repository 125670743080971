import { useState, useEffect } from "react";
import { getUserType } from "../../../services/userServices";
import * as donationServices from "../../../services/donationServices";
import ToastNotification from "../toastNotification";

const Export = (props) => {
  const [NotificationMessage, SetNotificationMessage] = useState(null);

  const getExportedData = async () => {
    SetNotificationMessage(null);
    const response = await donationServices.exportDonationData();
    SetNotificationMessage(response.message);
  };

  return (
    <>
      {NotificationMessage && (
        <ToastNotification message={NotificationMessage} />
      )}
      <button
        onClick={() => getExportedData()}
        className="btn-style-3 mt-10 pull-right"
      >
        <i className="fa fa-file-text"></i> Export
      </button>
    </>
  );
};

export default Export;
