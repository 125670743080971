import React, { Component } from "react";
import loadimage from "../../images/icons/wnf.png";

const Loader = () => {
  return (
    <div className="col-md-12 loading-icon">
      <div>
        <img className="fa-spin" src={loadimage} />
      </div>
    </div>
  );
};

export default Loader;
