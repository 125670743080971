import React from "react";
import { getUserType } from "../../services/userServices";
import icons from "./icons";
const CustomAlert = ({
  volunteers_not_accepted,
  status,
  charityBg,
  font = 20,
}) => {
  const userType = getUserType();
  const { donationSuccess, expired, waiting, charityClock } = icons;

  const AlertMessage = {
    donor: {
      waiting: "Waiting for charities to accept",
      reserved: "Donation request has been reserved",
      cancelled: "Donation request cancelled",
      expired: "Donation request is auto expired",
      completed: "Donation has been delivered successfully",
      delivered: "Donation has been delivered successfully",
      picked: "Donation has been picked up",
      accepted:
        "Donation delivery request has been accepted by volunteer, waiting for pickup",
    },
    charity: {
      expired: "Donation request is auto expired",
      reserved: "Donation reserved, waiting for pickup",
      completed: "Donation Received",
      delivered: "Donation Received",
      picked: "Donation is on the way",
      cancelled: "Donation pickup has been cancelled",
      accepted:
        "Donation delivery request has been accepted by volunteer, waiting for pickup",
    },
    volunteer: {
      expired: "Donation request is auto expired",
      accepted: "Pending for Pickup and Delivery",
      completed: "Donation Received",
      picked: "Donation is on the way",
      delivered: "Donation Received",
      cancelled: "Donation pickup has been cancelled",
    },
  };
  const AlertIcon = {
    donor: {
      waiting: waiting,
      reserved: waiting,
      accepted: waiting,
      cancelled: expired,
      expired: expired,
      completed: donationSuccess,
      delivered: donationSuccess,
      picked: waiting,
    },
    charity: {
      reserved: charityClock,
      completed: donationSuccess,
      delivered: donationSuccess,
      accepted: waiting,
      picked: waiting,
      cancelled: expired,
      expired: expired,
    },
    volunteer: {
      accepted: charityClock,
      completed: donationSuccess,
      picked: waiting,
      delivered: donationSuccess,
      cancelled: expired,
      expired: expired,
    },
  };

  const AlertColor = {
    donor: {
      waiting: "#E07603",
      reserved: "#E07603",
      accepted: "#E07603",
      cancelled: "#D53629",
      expired: "#D53629",
      completed: "#3EAB0D",
      delivered: "#3EAB0D",
      picked: "#E07603",
    },
    charity: {
      reserved: "#FFFFFF",
      completed: "#3EAB0D",
      delivered: "#3EAB0D",
      accepted: "#E07603",
      picked: "#E07603",
      cancelled: "#D53629",
      expired: "#D53629",
    },
    volunteer: {
      accepted: "#FFFFFF",
      completed: "#3EAB0D",
      delivered: "#3EAB0D",
      picked: "#E07603",
      cancelled: "#D53629",
      expired: "#D53629",
    },
  };
  const AlertBgColor = {
    donor: {
      waiting: "#FCF1E6",
      reserved: "#FCF1E6",
      cancelled: "#FBE8E6",
      expired: "#FBE8E6",
      completed: "#D8EECF",
      delivered: "#D8EECF",
      accepted: "#FCF1E6",
      picked: "#FCF1E6",
    },
    charity: {
      reserved: "#E07603",
      completed: "#D8EECF",
      delivered: "#D8EECF",
      accepted: "#FCF1E6",
      picked: "#FCF1E6",
      cancelled: "#FBE8E6",
      expired: "#FBE8E6",
    },
    volunteer: {
      accepted: "#E07603",
      completed: "#D8EECF",
      delivered: "#D8EECF",
      picked: "#FCF1E6",
      cancelled: "#FBE8E6",
      expired: "#FBE8E6",
    },
  };

  let text = AlertMessage[userType]?.[status];
  let icon = charityBg ? charityClock : AlertIcon[userType]?.[status];
  let color = charityBg ? "#FFFFFF" : AlertColor[userType]?.[status];
  let bgColor = charityBg ? "#E07603" : AlertBgColor[userType]?.[status];

  return (
    <div
      style={{
        backgroundColor: bgColor,
        color: color,
        fontWeight: "bold",
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px",
      }}
    >
      <div style={{ margin: "0", padding: "10px", fontSize: font }}>
        {volunteers_not_accepted && (
          <React.Fragment>
            <img className="mr-2" src={icon} alt="icon" />
            No Volunteer has accepted your request. Request you to pickup by
            yourself.
          </React.Fragment>
        )}
        {!volunteers_not_accepted && (
          <React.Fragment>
            <img className="mr-2" src={icon} alt="icon" />
            {text && text}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default CustomAlert;
