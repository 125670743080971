import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Test = () => {
  useEffect(() => {
    toast("Wow so easy!");
  });

  return (
    <div>
      <ToastContainer />
    </div>
  );
};

export default Test;
