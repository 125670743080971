import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import "./login.scss";
import * as authServices from "../../services/authServices";
import { Link } from "react-router-dom";
import Popup from "../common/popup";

class Login extends Form {
  state = {
    data: {
      email: "",
      password: "",
    },
    errors: [],
    value: 2,
  };

  schema = {
    email: Joi.string().email({
      minDomainSegments: 2,
    }),
    password: Joi.string().required().label("Password"),
  };

  displayErrors = () => {
    return this.state.errors.map((error) => {
      return <p key={error}>{error}</p>;
    });
  };

  doSubmit = async () => {
    try {
      const response = await authServices.login(
        this.state.data.email,
        this.state.data.password
      );
      if (response.message) {
        let errors = [];
        errors.push(response.message);
        this.setState({ errors });
      } else {
        window.location = "/home";
      }
    } catch (ex) {
      let errors = [];
      if (ex.response) errors.push(ex.response.data.message);
      this.setState({ errors });
    }
  };

  handleCategoryChange = (e) => {
    const user_type = e.target.value;
    const { data } = this.state;
    data.user_type = user_type;
    this.setState({ data });
  };

  render(props) {
    return (
      <React.Fragment>
        <div className="login-box">
          <form
            onSubmit={this.handleSubmit}
            className="box-style-1 col-lg-4 col-xs-12"
          >
            <div>{this.renderInput("email", "Email")}</div>
            <div>{this.renderInput("password", "Password", "password")}</div>

            {this.state.errors.length > 0 && (
              <Popup title="Error" body={<div>{this.displayErrors()}</div>} />
            )}
            <div>
              <Link className="pull-right mt-10" to="/forgotPassword">
                Forgot Password?
              </Link>
            </div>
            {/* <button disabled={this.validate()} className="btn btn-primary pull-right mt-10">Signup</button> */}
            <button
              disabled={this.validate()}
              className="btn btn-primary clear pull-right mt-10"
            >
              Login
            </button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
