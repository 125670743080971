import donationSuccess from "../../images/icons/donationSuccess.png";
import expired from "../../images/icons/expiry.png";
import waiting from "../../images/icons/waiting.png";
import donationValue from "../../images/icons/DonationValueIcon.svg";
import mealsDonated from "../../images/icons/Meals.svg";
import noOfCharities from "../../images/icons/CharityCountIcon.svg";
import share from "../../images/icons/share.png";
import clock from "../../images/icons/clock.png";
import send from "../../images/icons/send.png";
import instruction from "../../images/icons/instruction.png";
import charityClock from "../../images/icons/clock-d-res.png";
import defaultFoodIcon from "../../images/food.png";
import addIcon from "../../images/plus.png";
import defaultProfileIcon from "../../images/avatar.png";
import address from "../../images/icons/address.png";
import contact from "../../images/icons/contact.png";
import phone from "../../images/icons/phone.png";
import email from "../../images/icons/email.png";
import hours from "../../images/icons/hours.png";
import driven from "../../images/icons/driven.png";
import wnfImage from "../../images/icons/WNF-logo-image.jpg";
import edit from "../../images/icons/Edit.svg";
import del from "../../images/icons/Delete.svg";

const icons = {
  donationSuccess,
  expired,
  waiting,
  donationValue,
  mealsDonated,
  noOfCharities,
  share,
  clock,
  send,
  instruction,
  charityClock,
  defaultFoodIcon,
  addIcon,
  defaultProfileIcon,
  address,
  contact,
  phone,
  email,
  hours,
  driven,
  wnfImage,
  edit,
  del,
};
export default icons;
