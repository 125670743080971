import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const Popup = (props) => {
  const defaultDisplay = props.buttonTrigger || props.close ? false : true;
  const [show, setShow] = useState(defaultDisplay);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {props.buttonTrigger && (
        <div onClick={handleShow} className="col-md-12 pointer">
          {props.buttonTrigger}
        </div>
      )}

      <Modal
        size={props.size ? props.size : null}
        show={props.close ? !props.close : show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        {props.title && (
          <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body style={{ height: props.setHeight }}>
          {props.body}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Popup;
