import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import Dashlet from "../dashlet/dashlet";
import CharityActiveDonation from "../CharityActiveDonation/CharityActiveDonation";
import ActiveDonation from "../activeDonation/activeDonation";
import { getDisplayName, getUserType } from "../../services/userServices";
import VolunteerActiveDonation from "../volunteerActiveDonations/volunteerActiveDonation";

class Home extends Component {
  render() {
    const username = getDisplayName();
    return (
      <React.Fragment>
        <div className="homePage">
          <div className="row mt-20">
            <div className="col-md-6">
              <div className="title-1 p-10">Welcome {username}!</div>
            </div>
            {getUserType() === "donor" && (
              <div className="col-md-6">
                <NavLink
                  to="/home/add-donation"
                  className="btn-style-1 pull-right"
                >
                  Create New Donation
                </NavLink>
              </div>
            )}
          </div>
          <hr className="line-1" />
          <Dashlet />
          <div className="title-2 col-md-12 mb-10">
            <b>Active Donations</b>
          </div>
          {getUserType() === "donor" && <ActiveDonation />}
          {getUserType() === "charity" && <CharityActiveDonation />}
          {getUserType() === "volunteer" && <VolunteerActiveDonation />}
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
