import React, { Component } from "react";
import Popup from "../common/popup";
import ImgAfterUpload from "../common/ImgAfterUpload";
import { ProgressBar, Form, Button } from "react-bootstrap";
import { validate } from "../../validators/joiValidator";
import icons from "../common/icons";
import { uploadHandler } from "../../services/donationServices";
import "./AddItemPopupStyle.scss";

class AddItemPopup extends Component {
  state = {
    progress: null,
    close: false,
    data: {
      addItemdata: {
        addFood: "",
        quantity: "",
        addPhoto: "",
      },
    },
    errors: {
      addItemErrors: {
        addFood: undefined,
        quantity: undefined,
        addPhoto: undefined,
      },
    },
  };

  AddItemSchema = { ...this.props.AddItemSchema };

  onChangeAddItemData = async (inputValue, inputField) => {
    let image = null;
    let { progress } = { ...this.state };
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    const { addItemdata } = data;
    const { addItemErrors } = errors;
    if (inputField == "addPhoto" && inputValue) {
      this.setState({ progress: 50 });
      // const response = await this.uploader(inputValue);
      const response = await uploadHandler(inputValue);
      inputValue = response?.data?.fileName;
      progress = 100;
    }
    addItemdata[inputField] = inputValue;
    const validateData = {
      [inputField]: addItemdata[inputField],
    };
    const validateSchema = {
      [inputField]: this.AddItemSchema[inputField],
    };
    const error = validate(validateSchema, validateData);
    addItemErrors[inputField] = error[0];
    data["addItemdata"] = addItemdata;
    errors["addItemErrors"] = addItemErrors;
    this.setState({ data, errors, image, progress });
  };

  resetAddFoodForm = () => {
    const { data, errors, progress } = { ...this.state };
    const { addItemdata } = data;
    const { addItemErrors } = errors;
    for (let key in addItemdata) addItemdata[key] = "";
    for (let key in addItemErrors) addItemErrors[key] = undefined;
    this.setState({ data, errors, close: false, progress: 0 });
  };

  ErrorsInAddItem = () => {
    const errors = validate(this.AddItemSchema, {
      ...this.state.data.addItemdata,
    });
    if (errors.length > 0) return true;
    return false;
  };

  onSubmit = (e, addItemData) => {
    this.props.addFoodItem(e, addItemData);
    this.setState({ close: true });
  };

  render(props) {
    const { data, errors, close, progress } = { ...this.state };
    const { addItemdata } = data;
    const { addItemErrors } = errors;
    return (
      <Popup
        id="addItemPopup"
        title="Add Food Item"
        close={close}
        body={
          <Form
            enctype="multipart/formdata"
            onSubmit={(e) => this.onSubmit(e, addItemdata)}
          >
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Add Food</Form.Label>
              <Form.Control
                type="tes"
                placeholder="Add Food"
                value={addItemdata.addFood}
                onChange={(e) =>
                  this.onChangeAddItemData(e.target.value, "addFood")
                }
              />
              {addItemErrors.addFood && (
                <div className="alert alert-danger mt-10 ">
                  {addItemErrors.addFood}
                </div>
              )}
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="text"
                placeholder="Quantity"
                value={addItemdata.quantity}
                onChange={(e) =>
                  this.onChangeAddItemData(e.target.value, "quantity")
                }
              />
              {addItemErrors.quantity && (
                <div className="alert alert-danger mt-10 ">
                  {addItemErrors.quantity}
                </div>
              )}
            </Form.Group>

            <Form.Group>
              <Form.File
                onChange={(e) =>
                  this.onChangeAddItemData(e.target.files[0], "addPhoto")
                }
                name="photo"
                id="exampleFormControlFile1"
                label="Add Photo"
              />

              {
                <div style={{ width: "20%", marginTop: "5px" }}>
                  <ProgressBar now={progress} label={`${progress}%`} />
                </div>
              }
              {data.addItemdata.addPhoto && (
                <ImgAfterUpload fileName={data.addItemdata.addPhoto} />
              )}
            </Form.Group>

            <Button
              disabled={this.ErrorsInAddItem()}
              variant="primary"
              type="submit"
            >
              Submit
            </Button>
          </Form>
        }
        buttonTrigger={
          <React.Fragment>
            <img
              onClick={this.resetAddFoodForm}
              className="mr-2 avatar"
              src={icons.addIcon}
              alt="add-image"
            />
            Add Item
          </React.Fragment>
        }
      />
    );
  }
}

export default AddItemPopup;
