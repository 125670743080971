import React, { Component } from "react";
import "./volunteerDonationDetail.scss";
import { NavLink } from "react-router-dom";
import icons from "../../common/icons";
import donationServices from "../../../services/donationServices";
import Loader from "../../common/loader";
import getDateString, { AMPMTime } from "../../common/commonFunction";
import { imageURL } from "../../../config.json";
import CancelPickup from "../../CancelPickupModal/cancelPickupModal";
import Mail from "../../common/Mail";

const { clock, instruction } = icons;

class VolunteerDonationDetail extends Component {
  state = {
    pickUpCode: "",
    loading: true,
    donationDetail: null,
    charities: null,
    selfPickup: true,
    closePopUp: false,
  };
  donationId = this.props.match.params?.id;

  componentDidMount = async () => {
    const donationDetail = await donationServices.getDonationInfoForVolunteer(
      this.donationId
    );
    this.setState({ donationDetail, loading: false });
  };

  returnCharityAddress = (charityDetail) => {
    const { address, city, state, zipcode } = charityDetail;
    return (
      <p className="normal-text">
        {address},{city},{state},{zipcode}
      </p>
    );
  };

  deliverDonation = async () => {
    const response = await donationServices.DeliverDonation(this.donationId);
    if (response.status === "success") {
      this.props.sendNotification("Donation has been delivered!");
      this.props.history.push("/donation-history");
    }
  };

  cancelPickup = async (id, comments) => {
    const response = await donationServices.cancelDonation(id, comments);
    if (response === "success") {
      this.props.sendNotification("Donation has been cancelled!");
      this.props.history.push("/home");
    }
  };

  acceptRequest = async () => {
    const response = await donationServices.AcceptRequest(this.donationId);
    if (response.status === "success") {
      this.props.sendNotification("Donation has been Accepted!");
      this.props.history.push("/donation");
    }
  };

  displayActionButtons = () => {
    const { donationDetail } = { ...this.state };
    if (donationDetail.status === "reserved") {
      return (
        <button
          onClick={() => this.acceptRequest()}
          className="btn-style-1 pull-right mt-20 ml-10"
        >
          Accept Request
        </button>
      );
    }
    if (donationDetail.status === "accepted") {
      return (
        <>
          <button
            onClick={() => this.deliverDonation()}
            className="btn-style-1 pull-right mt-20 ml-10"
          >
            Mark as Delivered
          </button>
          <CancelPickup
            cancelPickup={this.cancelPickup}
            donationId={this.donationId}
          />
        </>
      );
    }
    if (donationDetail.status === "picked") {
      return (
        <button
          onClick={() => this.deliverDonation()}
          className="btn-style-1 pull-right mt-20 ml-10"
        >
          Mark as Delivered
        </button>
      );
    }
  };

  render() {
    const { loading, donationDetail } = { ...this.state };
    return loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        <div className="row mt-20">
          <div
            className="col-md-12"
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <NavLink to="/home" className="btn-style-2 btn">
              <i className="fa fa-chevron-left"></i>
            </NavLink>
            <div
              className="title-1"
              style={{ padding: 20, display: "inline-block" }}
            >
              Donation Details
            </div>
          </div>

          {/* <div className="col-md-4">
            <NavLink
              to={"/donation/edit/" + this.props?.match?.params?.id}
              className="btn-style-1 pull-right mt-20 ml-10"
            >
              Accept Request
            </NavLink>
          </div> */}
        </div>
        <div className="volunteerDetail">
          <div className="box-style-2 volunteer-grid">
            <div className="section1">
              <div className="container">
                <div className="row p-2">
                  <div className="left col-md-12">
                    <p>{donationDetail.food_description}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="line-2" />
            <div className="section2">
              <div className="left col-md-12">
                <p className="text-muted mb-2">
                  <img src={clock} alt={clock} /> Pickup Date & Time
                </p>
                <p className="normal-text boldFont">
                  {getDateString(donationDetail?.pickup_time)}
                </p>
              </div>
            </div>
            <hr className="line-2" />
            <div className="map">
              <div className="section2 pickup">
                <div className="left col-md-12">
                  <p className="text-muted mb-2"> Pickup Location</p>
                  <p className="normal-text boldFont">
                    {donationDetail.pickup_location}
                  </p>
                </div>
              </div>
              <hr className="line-2" />
              <div className="section2 delivery">
                <div className="left col-md-12">
                  <p className="text-muted mb-2"> Delivery Location</p>
                  <p className="boldFont">
                    {this.returnCharityAddress(donationDetail.charity)}
                  </p>
                </div>
              </div>
            </div>
            <hr className="line-2" />
            <div className="section2">
              <div className="left col-md-12">
                <p className="text-muted mb-2">
                  <img src={instruction} alt={instruction} /> Specific
                  Instructions
                </p>
                <p className="normal-text boldFont">
                  {donationDetail.pickup_instruction}
                </p>
              </div>
            </div>
          </div>
          <div className="box-style-2 volunteer-grid food-items">
            <div className="section1 ">
              <div className="left col-md-12 p-2">
                <p>Food Items</p>
              </div>
            </div>

            {donationDetail.food_items.map((food, key) => (
              <div key={key}>
                <hr className="line-2" />
                <div className="section2">
                  <div className="media">
                    <div className="media-left">
                      <img
                        className="mr-2 avatar"
                        src={
                          food.picture !== ""
                            ? imageURL + food.picture
                            : icons.defaultFoodIcon
                        }
                        alt="avatar"
                      />
                    </div>
                    <div className="media-body">
                      <h4 className="media-heading">{food.foodname}</h4>
                      <p className="text-muted">{food.quantity}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="box-style-2 volunteer-grid">
            <div className="section1">
              <div className="left col-md-12 p-2">
                <p>
                  {donationDetail.status === "reserved"
                    ? "Reserved by"
                    : " Donated To"}
                </p>
              </div>
            </div>
            <hr className="line-2" />
            <div className="section2">
              <div className="media">
                <div className="media-left">
                  <img
                    className="mr-2 profile-image"
                    src={
                      donationDetail.charity.picture === "null" ||
                      donationDetail.charity.picture === null
                        ? icons.defaultProfileIcon
                        : imageURL + donationDetail.charity.picture
                    }
                    alt="avatar"
                  />
                </div>
                <div className="media-body">
                  <h4 className="media-heading">
                    {donationDetail.charity.charity_name}
                    <p className="text-muted">
                      {donationDetail.charity.distance} miles
                    </p>
                  </h4>
                </div>
              </div>
            </div>

            <div className="section2">
              <div className="left col-md-12">
                <p className="text-muted">
                  <img className="mr-2" src={icons.address} alt="icon" />
                  Address
                </p>
                <p className="boldFont">
                  {" "}
                  {this.returnCharityAddress(donationDetail.charity)}
                </p>
              </div>
            </div>
            <div className="section2">
              <div className="left col-md-12">
                <p className="text-muted">
                  <img className="mr-2" src={icons.contact} alt="icon" />{" "}
                  Contact Person
                </p>
                <p className="normal-text boldFont">
                  {donationDetail.charity.contact_person}
                </p>
              </div>
            </div>
            <div className="section2">
              <div className="left col-md-12">
                <p className="text-muted">
                  <img className="mr-2" src={icons.phone} alt="icon" /> Phone
                </p>
                <p className="normal-text boldFont">
                  {donationDetail.charity.users[0].phone}
                </p>
              </div>
            </div>
            <div className="section2">
              <div className="left col-md-12">
                <p className="text-muted">
                  <img className="mr-2" src={icons.email} alt="icon" /> Email
                </p>
                <Mail to={donationDetail.charity.users[0].email} />
              </div>
            </div>
          </div>

          <div className="box-style-2 volunteer-grid">
            <div className="section1">
              <div className="left col-md-12 p-2">
                <p>Donated by</p>
              </div>
            </div>

            <hr className="line-2" />
            <div className="section2">
              <div className="media">
                <img
                  className="mr-2 profile-image"
                  src={
                    donationDetail.donor.picture === "null" ||
                    donationDetail.donor.picture === null
                      ? icons.defaultProfileIcon
                      : imageURL + donationDetail.donor.picture
                  }
                  alt="avatar"
                />
                <div className="media-body">
                  <h4 className="media-heading">
                    {donationDetail.donor.donor_name}
                  </h4>
                  <p className="text-muted">
                    {donationDetail.donor.distance} miles
                  </p>
                </div>
              </div>
            </div>

            <div className="section2">
              <div className="left col-md-12">
                <p className="text-muted">
                  <img className="mr-2" src={icons.address} alt="icon" />
                  Address
                </p>
                <p className="boldFont">
                  {this.returnCharityAddress(donationDetail.donor)}
                </p>
              </div>
            </div>
            <div className="section2">
              <div className="left col-md-12">
                <p className="text-muted">
                  <img className="mr-2" src={icons.contact} alt="icon" />{" "}
                  Contact Person
                </p>
                <p className="normal-text boldFont">
                  {donationDetail.donor.contact_person}
                </p>
              </div>
            </div>
            <div className="section2">
              <div className="left col-md-12">
                <p className="text-muted">
                  <img className="mr-2" src={icons.phone} alt="icon" /> Phone
                </p>
                <p className="normal-text boldFont">
                  {donationDetail.donor.users[0].phone}
                </p>
              </div>
            </div>
            <div className="section2">
              <div className="left col-md-12">
                <p className="text-muted">
                  <img className="mr-2" src={icons.email} alt="icon" /> Email
                </p>
                <Mail to={donationDetail.donor.users[0].email} />
              </div>
            </div>
          </div>

          {/* {donationDetail.status === "reserved" && ( */}
          <div className="volunteer-pickup">
            <div className="volunteer-time">
              <h5>Pickup the food by</h5>
              <span>{AMPMTime(donationDetail.pickup_time)}</span>
            </div>
            <div className="volunteer-code">
              <h5>Your Pickup Code</h5>
              <span className="text-muted">
                Show pickup code to the donor to confirm pickup
              </span>
              <span className="pincode">{donationDetail.passcode}</span>
            </div>
          </div>
          {/* )} */}
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-5 title-1 p-10"></div>

          <div className="col-md-6">{this.displayActionButtons()}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default VolunteerDonationDetail;
