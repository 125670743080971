import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import Popup from "./../common/popup";
import "./ForgotPassword.scss";
import * as authServices from "../../services/authServices";
import { Link } from "react-router-dom";

class ForgotPassword extends Form {
  state = {
    showPopup: false,
    data: {
      email: "",
      password: "",
    },
    errors: [],
    value: 2,
  };

  schema = {
    email: Joi.string()
      .email({
        minDomainSegments: 2,
        tlds: { allow: ["com", "net"] },
      })
      .label("Email"),
  };

  componentDidMount = () => {
    this.setState({ showPopup: false });
  };

  displayErrors = () => {
    return this.state.errors.map((error) => {
      return <p key={error}>{error}</p>;
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = { ...this.state.data };
    try {
      const response = await authServices.passwordReset(email);
      if (response.status === 200) {
        this.setState({ showPopup: true });
      }
    } catch (ex) {
      let errors = [];
      if (ex.response) errors.push(ex.response.data.message);
      this.setState({ errors });
    }
  };

  handleCategoryChange = (e) => {
    const user_type = e.target.value;
    const { data } = this.state;
    data.user_type = user_type;
    this.setState({ data });
  };

  render(props) {
    const { showPopup } = { ...this.state };
    return (
      <React.Fragment>
        {showPopup && (
          <Popup
            body={
              <>
                <p>
                  An email has been sent to the address which you have provided.
                </p>
                <button
                  className="btn btn-primary pull-right"
                  onClick={() => this.setState({ showPopup: false })}
                >
                  Ok
                </button>
              </>
            }
          />
        )}
        <div className="forgot-box">
          <form
            onSubmit={this.handleSubmit}
            className="box-style-1 col-lg-4 col-xs-12"
          >
            <h2 className="text-center">Reset Password</h2>
            <br />
            <div>
              <p className="text-muted">
                Enter the email address associated with your account and we will
                send an email with instructions to reset your password
              </p>
            </div>
            <div>
              {this.renderInput("email", null, null, null, "Email Address")}
            </div>

            {this.state.errors.length > 0 && (
              <div className="alert alert-danger mt-10 ">
                {this.displayErrors()}
              </div>
            )}
            <div className="center-align">
              <button type="submit" className="btn btn-primary clear  mt-10">
                Send Instructions
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
