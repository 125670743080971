import React from "react";
import Form from "../common/form";
import Joi from "joi-browser";
import { ProgressBar } from "react-bootstrap";
import * as userServices from "../../services/userServices";
import { uploadHandler } from "../../services/donationServices";

import "./profile.scss";
import ImageAfterUpload from "../common/ImgAfterUpload";
import { getUserType } from "./../../services/userServices";
import Popup from "../common/popup";

class Profile extends Form {
  state = {
    show: false,
    message: null,
    progress: 0,
    readOnly: true,
    data: {
      displayName: "",
      phone: "",
      email: "",
      contact_person: "",
      address: "",
      zipcode: "",
      city: "",
      state: "",
      account_type: "",
      cuisine: "",
      question: "",
      answer: "",
      picture: null,
    },
    errors: [],
    value: 2,
  };

  schema = {
    displayName: Joi.string().required().label("Name"),
    phone: Joi.string().required().label("Phone"),
    contact_person: Joi.string().required().label("Contact Person").allow(null),
    address: Joi.string().required().label("Address"),
    zipcode: Joi.string().required().label("Zip code"),
    city: Joi.string().required().label("City"),
    state: Joi.string().required().label("State"),
    account_type: Joi.string().required().label("Type of Donor").allow(null),
    cuisine: Joi.string().required().label("Cuisine").allow(null),
    question: Joi.string().required().label("Question"),
    answer: Joi.string().required().label("Answer"),
    email: Joi.string().email({
      minDomainSegments: 2,
      tlds: { allow: ["com", "net"] },
    }),
    picture: Joi.string().required().label("picture"),
  };

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  componentDidMount = () => {
    const type = userServices.getUserType();
    const userDetails = userServices.userDetails;
    const data = { ...this.state.data };
    data.displayName = userDetails[type + "_name"];
    data.phone = userDetails?.phone;
    data.email = userDetails?.email;
    data.contact_person = userDetails?.contact_person;
    data.address = userDetails?.address;
    data.zipcode = userDetails?.zipcode;
    data.city = userDetails?.city;
    data.state = userDetails?.state;
    data.account_type = userDetails?.account_type;
    data.cuisine = userDetails?.cuisine;
    data.question = userDetails?.question;
    data.answer = userDetails?.answer;
    data.picture = userDetails?.picture;
    this.setState({ data });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.readOnly) {
      this.setState({ readOnly: false });
    } else {
      try {
        const response = await userServices.update(this.state.data);
        if ((response.status = 200)) {
          userServices.updateUserDetails(this.state.data);
          this.setState({ show: true, message: "Updated Successfully." });
        }
      } catch (ex) {
        let errors = [];
        if (ex.response) errors.push(ex.response.data.message);
        this.setState({ errors });
      }
    }
  };
  handleCategoryChange = (e) => {
    const account_type = e.target.value;
    const { data } = this.state;
    data.account_type = account_type;
    this.setState({ data });
  };

  handleCuisineChange = (e) => {
    const cuisine = e.target.value;
    const { data } = this.state;
    data.cuisine = cuisine;
    this.setState({ data });
  };

  handleQuestionsChange = (e) => {
    const question = e.target.value;
    const { data } = this.state;
    data.question = question;
    this.setState({ data });
  };

  onChangePhoto = async (inputValue, inputField) => {
    let image = null;
    let { data, progress } = { ...this.state };
    const errors = { ...this.state.errors };
    if (inputField == "picture" && inputValue) {
      this.setState({ progress: 50 });
      const response = await uploadHandler(inputValue);
      inputValue = response?.data?.fileName;
      if (response?.data?.fileName) progress = 100;
    }
    data[inputField] = inputValue;
    this.setState({ data, errors, progress });
  };

  displayErrors = () => {
    return this.state.errors.map((error) => {
      return <p key={error}>{error}</p>;
    });
  };

  handleReadOnly = () => {
    let { readOnly } = { ...this.state };
    readOnly = !readOnly;
    this.setState({ readOnly });
  };

  removePicture = () => {
    this.setState({ progress: 0 });
    let state = { ...this.state };
    state.data.picture = "";
    this.textInput.current.value = "";
    this.setState({ state });
  };

  render(props) {
    const { progress, show, message } = { ...this.state };
    const readOnly = this.state.readOnly;
    return (
      <React.Fragment>
        {show && (
          <Popup
            size="sm"
            body={
              <>
                <h5>{message}</h5>
                <button
                  className="btn btn-sm pull-right btn-primary"
                  onClick={() => window.location.reload()}
                >
                  Ok
                </button>
              </>
            }
          />
        )}
        <div className="signup-box">
          <form
            style={{ "margin-top": "20px", padding: "30px" }}
            className="box-style-1 col-md-10 col-md-offset-4"
          >
            <button
              onClick={this.handleSubmit}
              style={{ float: "right" }}
              className="btn btn-primary btn-sm mt-10"
            >
              {readOnly ? "Edit" : "Save"}
            </button>

            <div className="row">
              <div className="col-lg-6 col-xs-12">
                <div>
                  {this.renderInput(
                    "displayName",
                    "Name",
                    "text",
                    null,
                    null,
                    readOnly
                  )}
                </div>
                <div>
                  {this.renderInput(
                    "phone",
                    "Phone",
                    "text",
                    null,
                    null,
                    readOnly
                  )}
                </div>
                <div>
                  {this.renderInput(
                    "email",
                    "Email",
                    "text",
                    null,
                    null,
                    readOnly
                  )}
                </div>
                {getUserType() !== "volunteer" && (
                  <div>
                    {this.renderInput(
                      "contact_person",
                      "Contact Person",
                      "text",
                      null,
                      null,
                      readOnly
                    )}
                  </div>
                )}
                <div>
                  {this.renderInput(
                    "address",
                    "Address",
                    "text",
                    null,
                    null,
                    readOnly
                  )}
                </div>
                <div>
                  {this.renderInput(
                    "zipcode",
                    "Zip code",
                    "text",
                    null,
                    null,
                    readOnly
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-xs-12">
                <div>
                  {this.renderInput(
                    "city",
                    "City",
                    "text",
                    null,
                    null,
                    readOnly
                  )}
                </div>
                <div>
                  {this.renderInput(
                    "state",
                    "State",
                    "text",
                    null,
                    null,
                    readOnly
                  )}
                </div>
                {userServices.getUserType() === "donor" && (
                  <div className="form-group">
                    <label>Type of Donor</label>
                    <select
                      name="account_type"
                      className="form-control"
                      value={this.state.data.account_type}
                      onChange={!readOnly && this.handleCategoryChange}
                      disabled={readOnly}
                    >
                      <option value="">Type of Donor</option>
                      <option value="Farm">Farm</option>
                      <option value="Grocery">Grocery</option>
                      <option value="Other">Other</option>
                      <option value="Restaurant">Restaurant</option>
                    </select>
                  </div>
                )}
                {userServices.getUserType() === "donor" && (
                  <div className="form-group">
                    <label>Cuisine</label>
                    <select
                      name="cuisine"
                      className="form-control"
                      value={this.state.data.cuisine}
                      onChange={this.handleCuisineChange}
                      disabled={readOnly}
                    >
                      <option value="">Cuisine</option>
                      <option value="American">American</option>
                      <option value="Bakery">Bakery</option>
                      <option value="Coffee Shop">Coffee Shop</option>
                      <option value="French">French</option>
                      <option value="Greek">Greek</option>
                      <option value="Indian">Indian</option>
                      <option value="Italian">Italian</option>
                      <option value="Japanese">Japanese</option>
                      <option value="Mexican">Mexican</option>
                      <option value="Middle Eastern">Middle Eastern</option>
                      <option value="Thai">Thai</option>
                      <option value="Coffee Shop">Other</option>
                    </select>
                  </div>
                )}
                <div className="form-group">
                  <label>Security Question</label>
                  <select
                    name="question"
                    className="form-control"
                    value={this.state.data.question}
                    onChange={this.handleQuestionsChange}
                    disabled={readOnly}
                  >
                    <option value="">Security Question</option>
                    <option value="What is your best friend's name from childhood?">
                      What is your best friend's name from childhood?
                    </option>
                    <option value="What was your first school?">
                      What was your first school?
                    </option>
                    <option value="Which city were you born?">
                      Which city were you born?
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  {this.renderInput(
                    "answer",
                    "Answer",
                    "text",
                    null,
                    null,
                    readOnly
                  )}
                </div>
                <div className="form-group">
                  <span onClick={this.removePicture} className="close">
                    <i
                      class="fa fa-times-circle text-danger"
                      aria-hidden="true"
                    ></i>
                  </span>
                  <label>Upload profile picture</label>
                  <div>
                    <input
                      type="file"
                      onChange={(e) =>
                        this.onChangePhoto(e.target.files[0], "picture")
                      }
                      ref={this.textInput}
                      name="picture"
                      id="profilePic"
                      label="Add Photo"
                      disabled={readOnly}
                    />
                    <div style={{ width: "20%", marginTop: "5px" }}>
                      <ProgressBar now={progress} label={`${progress}%`} />
                    </div>
                  </div>
                  <div>
                    {this.state.data.picture && (
                      <ImageAfterUpload fileName={this.state.data.picture} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default Profile;
