import React, { Component } from "react";
import "./dashlet.scss";
import { apiUrl } from "../../config.json";
import http from "../../services/httpService";
import { getDashboardData } from "../../services/userServices";
class Dashlet extends Component {
  state = {
    dashboardData: [],
  };

  componentDidMount = async () => {
    const dashboardData = await getDashboardData();
    this.setState({ dashboardData: dashboardData });
  };

  getDashboardBody = (dashboard) => {
    if (dashboard.dashboardName == "DONATION VALUE")
      return dashboard?.dashboardValue ? "$" + dashboard.dashboardValue : "$0";
    else return dashboard?.dashboardValue ? dashboard.dashboardValue : 0;
  };

  getDashboards = () => {
    const dashboardData = [...this.state.dashboardData];
    return dashboardData.map((dashboard, key) => {
      return (
        <div key={key} className="box-style-1 dashlets">
          <div className="col-md-12  dashlets-content">
            <div className="row align-center">
              <div className="col-10">
                <p>{dashboard.dashboardName}</p>
                <span>{this.getDashboardBody(dashboard)}</span>
              </div>
              <div className="col-2 flex-j-c icon">
                <img src={dashboard.icon} alt="icon" />
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    const { dashboardData } = { ...this.state };
    return (
      <React.Fragment>
        <div className="grid-container col-md-12 mb-25">
          {this.getDashboards()}
        </div>
      </React.Fragment>
    );
  }
}

export default Dashlet;
