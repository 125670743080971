import http from "./httpService";
import { apiUrl, imageURL, googleMapUrl } from "../config.json";
import _ from "lodash";
import icons from "../components/common/icons";
const apiEndpoint = apiUrl + "/auth/register";
export const userDetails = JSON.parse(localStorage.getItem("userDetails"));
const apiEndpoint1 = apiUrl + "/donors/users/" + userDetails?.user_id;

const { donationValue, mealsDonated, noOfCharities, hours, driven } = icons;

/** updates miles and notification enabled for mobile */
export const updateUserProfileSettings = async (
  miles,
  is_notification_enabled
) => {
  const userType = getUserType();
  if (userType === "charity") {
    const apiEndpoint = apiUrl + "/charities/" + userDetails.charity_id;
    let { data: settingsData } = await http.put(apiEndpoint, {
      charity_id: userDetails.charity_id,
      notification_range: miles,
      notification_enabled: is_notification_enabled,
      user_id: userDetails.user_id,
    });
    return settingsData;
  }
  if (userType === "volunteer") {
    const apiEndpoint = apiUrl + "/volunteers/" + userDetails.volunteer_id;
    let { data: settingsData } = await http.put(apiEndpoint, {
      volunteer_id: userDetails?.volunteer_id,
      notification_range: miles,
      user_id: userDetails.user_id,
      notification_enabled: is_notification_enabled,
    });
    return settingsData;
  }
  if (userType === "donor") {
    const apiEndpoint = apiUrl + "/donors/" + userDetails.donor_id;
    let { data: settingsData } = await http.put(apiEndpoint, {
      donor_id: userDetails?.donor_id,
      user_id: userDetails.user_id,
      notification_enabled: is_notification_enabled,
    });
    return settingsData;
  }
};
export const getUserAddress = () => {
  const { address, city, state } = userDetails;
  return `${address},${city},${state}`;
};

export async function register(user) {
  return await http.post(apiEndpoint, {
    name: user.name,
    email: user.email,
    password: user.password,
    user_type: user.user_type,
    account_type: user.account_type,
    phone: user.phone,
    contact_person: user.contact_person,
    c_number: user.c_number,
    address: user.address,
    zipcode: user.zipcode,
    city: user.city,
    state: user.state,
    cuisine: user.cuisine,
    question: user.question,
    answer: user.answer,
    picture: user.addPhoto,
  });
  // return "Received from react and Retrun from node successfully";
}

export const getUserType = () => {
  return userDetails?.user_category;
};

export const getUserDisplayIcon = () => {
  return userDetails?.picture ? imageURL + userDetails.picture : null;
};

export const getAllCharityUsers = async () => {
  const apiEndpoint = apiUrl + "/charities";
  let { data: charities } = await http.get(apiEndpoint);
  return charities;
};

export const getAllDesignatedCharityUsers = async (address) => {
  const apiEndpoint = apiUrl + "/donors/designatedCharities";
  var lat = userDetails?.latitude;
  var lng = userDetails?.longitude;
  if (address && address != "") {
    const mapUrl =
      googleMapUrl +
      "&address=" +
      address +
      "&key=AIzaSyBXh89svf5gu11rHYVG7F9SPa32lKcWtZc";
    let { data } = await http.get(mapUrl);

    if (data && data.results  && data.results[0] && data.results[0].geometry) {
      var geo = data.results[0].geometry;
      if (geo.location) {
        lat = geo.location.lat;
        lng = geo.location.lng;
      }
    }
  }
  let { data: charities } = await http.post(apiEndpoint, {
    donor_id: userDetails?.donor_id,
    active: true,
    latitude: lat,
    longitude: lng,
  });
  return charities;
};

export const getDisplayName = () => {
  if (userDetails?.user_category === "donor") return userDetails?.donor_name;
  else if (userDetails?.user_category === "charity")
    return userDetails?.charity_name;
  return userDetails?.volunteer_name;
};

export async function getDonorUserAccount(email) {
  const { data: getUser } = await http.get(
    "http://localhost:3000/api/v1/donors/47"
  );
  const userFilter = getUser.data.users.filter(
    (user) => user.is_active == true
  );
  return userFilter.filter((admin) => admin.email !== email);
}

/**
 * To get the dashboard data for all the users
 */

export const getDashboardData = async () => {
  const userType = getUserType();
  //---------- DONOR DASHBOARD DATA---------------
  if (userType === "donor") {
    const apiEndpoint = apiUrl + "/donors/dashboard";
    let { data: dashboardData } = await http.post(apiEndpoint, {
      donor_id: userDetails?.donor_id,
    });
    dashboardData = dashboardData.data;
    return [
      {
        dashboardName: "DONATION VALUE",
        dashboardValue: dashboardData?.amount,
        icon: donationValue,
      },
      {
        dashboardName: "MEALS DONATED",
        dashboardValue: dashboardData?.meals,
        icon: mealsDonated,
      },
      {
        dashboardName: "NO. OF CHARITIES",
        dashboardValue: dashboardData?.charities,
        icon: noOfCharities,
      },
    ];
  }
  //---------- CHARITY DASHBOARD DATA---------------
  if (userType === "charity") {
    const apiEndpoint = apiUrl + "/charities/dashboard";
    let { data: dashboardData } = await http.post(apiEndpoint, {
      charity_id: userDetails?.charity_id,
    });
    dashboardData = dashboardData.data;
    return [
      {
        dashboardName: "DONATION VALUE",
        dashboardValue: dashboardData?.amount,
        icon: donationValue,
      },
      {
        dashboardName: "MEALS RECEIVED",
        dashboardValue: dashboardData?.meals,
        icon: mealsDonated,
      },
      {
        dashboardName: "NUMBER OF DONORS",
        dashboardValue: dashboardData?.donors,
        icon: noOfCharities,
      },
    ];
  }
  //---------- VOLUNTEER DASHBOARD DATA---------------
  if (userType === "volunteer") {
    const apiEndpoint = apiUrl + "/volunteers/dashboard";
    let { data: dashboardData } = await http.post(apiEndpoint, {
      volunteer_id: userDetails?.volunteer_id,
    });
    dashboardData = dashboardData.data;
    return [
      {
        dashboardName: "Hours Volunteered",
        dashboardValue: dashboardData?.amount,
        icon: hours,
      },
      {
        dashboardName: "Meals Delivered",
        dashboardValue: dashboardData?.meals,
        icon: mealsDonated,
      },
      {
        dashboardName: "Miles Driven",
        dashboardValue: dashboardData?.charities,
        icon: driven,
      },
    ];
  }
};

/**
 * To get all the donations that are to be accepted by the charity
 */

export const getAcceptedDonations = async (miles) => {
  const userType = getUserType();
  if (userType === "charity") {
    const apiEndpoint = apiUrl + "/charities/donations/active";
    let { data: dashboardData } = await http.post(apiEndpoint, {
      charity_id: userDetails?.charity_id,
      miles: miles,
    });
    return dashboardData?.data;
  }
};

/**
 * To get all the users in the settings page
 */

export const getAllUsers = async () => {
  const userType = getUserType();
  if (userType === "charity") {
    const apiEndpoint = apiUrl + "/charities/users";
    let { data: dashboardData } = await http.post(apiEndpoint, {
      charity_id: userDetails?.charity_id,
    });
    const users = dashboardData?.data.filter(
      (user) => user.user_id !== userDetails.user_id
    );
    return users;
  }
  if (userType === "donor") {
    const apiEndpoint = apiUrl + "/donors/users";
    let { data: dashboardData } = await http.post(apiEndpoint, {
      donor_id: userDetails?.donor_id,
    });
    const users = dashboardData?.data.filter(
      (user) => user.user_id !== userDetails.user_id
    );
    return users;
  }
  if (userType === "volunteer") {
    const apiEndpoint = apiUrl + "/volunteers/users";
    let { data: dashboardData } = await http.post(apiEndpoint, {
      volunteer_id: userDetails?.volunteer_id,
    });
    const users = dashboardData?.data.filter(
      (user) => user.user_id !== userDetails.user_id
    );
    return users;
  }
};

/** To create a new User in the settings page */
export const AddNewUser = async (user) => {
  if (getUserType() === "charity") {
    const apiEndPoint = apiUrl + "/charities/addUser";
    const { data: response } = await http.post(apiEndPoint, {
      ...user,
      charity_id: userDetails?.charity_id,
    });
    return response.status;
  }
  if (getUserType() === "donor") {
    const apiEndPoint = apiUrl + "/donors/addUser";
    const { data: response } = await http.post(apiEndPoint, {
      ...user,
      donor_id: userDetails?.donor_id,
    });
    return response.status;
  }
  if (getUserType() === "volunteer") {
    const apiEndPoint = apiUrl + "/volunteers/addUser";
    const { data: response } = await http.post(apiEndPoint, {
      ...user,
      volunteer_id: userDetails?.volunteer_id,
    });
    return response.status;
  }
};

/** To delete a User in the settings page */
export const DeleteUser = async (userId) => {
  if (getUserType() === "charity") {
    const apiEndPoint = apiUrl + "/charities/users/" + userId;
    const { data: response } = await http.put(apiEndPoint, {
      charity_id: userDetails?.charity_id,
      is_active: false,
    });
    return response.status;
  }
  if (getUserType() === "donor") {
    const apiEndPoint = apiUrl + "/donors/users/" + userId;
    const { data: response } = await http.put(apiEndPoint, {
      donor_id: userDetails?.donor_id,
      is_active: false,
    });
    return response.status;
  }
};

/** To Update an User details in the settings page */
export const updateUser = async (user) => {
  if (getUserType() === "charity") {
    const apiEndPoint = apiUrl + "/charities/users/" + user.user_id;
    const { data: response } = await http.put(apiEndPoint, { ...user });
    return response.status;
  }
  if (getUserType() === "donor") {
    const apiEndPoint = apiUrl + "/donors/users/" + user.user_id;
    const { data: response } = await http.put(apiEndPoint, { ...user });
    return response.status;
  }
};

/** To Update the user data in profile page */
export async function update(user) {
  let apiEndpoint = apiUrl;
  let name;
  let username = getUserType() + "_name";
  if (getUserType() === "donor") {
    name = "donors/" + userDetails.donor_id;
  }

  if (getUserType() === "charity") {
    name = "charities/" + userDetails.charity_id;
  }

  if (getUserType() === "volunteer") {
    name = "volunteers/" + userDetails.volunteer_id;
  }
  apiEndpoint = apiEndpoint + "/" + name;
  return await http.put(apiEndpoint, {
    [username]: user["displayName"],
    user_id: userDetails.user_id,
    email: user.email,
    user_type: user.user_type,
    account_type: user.account_type,
    phone: user.phone,
    contact_person: user.contact_person,
    address: user.address,
    zipcode: user.zipcode,
    city: user.city,
    state: user.state,
    cuisine: user.cuisine,
    question: user.question,
    answer: user.answer,
    picture: user.picture,
  });
  // return "Received from react and Retrun from node successfully";
}

export const updateUserDetails = (userData) => {
  const type = getUserType();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  for (let key in userDetails) {
    if (key === type + "_name") {
      userDetails[key] = userData["displayName"];
    } else {
      userDetails[key] = userData[key] ? userData[key] : userDetails[key];
    }
  }
  localStorage.setItem("userDetails", JSON.stringify(userDetails));
};

export const updateUserLocalSettings = (miles, is_notification_enabled) => {
  console.log(miles, is_notification_enabled);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  if (userDetails.notification_range) userDetails.notification_range = miles;
  userDetails.is_notification_enabled = is_notification_enabled;
  localStorage.setItem("userDetails", JSON.stringify(userDetails));
};
