import React, { Component } from "react";
import "./faq.scss";

class Faq extends Component {
  render() {
    const faqs = [
      {
        question:
          "How does Waste No Food address a restaurant's liability fears?",
        answer:
          "All 50 states and the District of Columbia have in place a law, called the Good Samaritan Law. This protects all donors from liability and the organizations handling the food are responsible for all liability.",
      },
      {
        question: "Who facilitates the transportation of food?",
        answer:
          "The organizations are responsible for the transportation of all food. Many organizations have refrigerated trucks for this purpose. If an organization does not consistently pick up food it claims, then restaurants can choose not to work with the organization.",
      },
      {
        question:
          "How do restaurants know that the charities donating are legitimate?",
        answer:
          "In order for an organization to participate in Waste No Food, the organization must be vetted for authenticity. Waste No Food assures donors that only legitimate charities can access the website to secure donations.",
      },
      {
        question: "What's in it for the organizations?",
        answer:
          "During the recession, charities have experienced a sharp drop in donations, but demand for their services have been higher than ever. In fact many people who have been donors to charities, are now requiring assistance. Organizations need more resources and Waste No Food provides them with these resources.",
      },
      {
        question:
          "Can organizations also rate the effectiveness of restaurants?",
        answer:
          "Yes. It is in fact encouraged that both organizations and restaurants rate each other. This is a good way to identify the most reliable entities and to help other members of Waste No Food make the best decision on whom to collaborate with.",
      },
    ];
    return (
      <React.Fragment>
        <div className="row mt-20">
          <div className="col mt-10">
            <div className="col-md-12">
              <div className="title-1">FAQ</div>
            </div>
            <hr className="line-1" />
          </div>
          <div className="faq-container">
            {faqs.map((faq, key) => (
              <div key={key} className="box-style-4">
                <div className="section1">
                  <div className="text-container col-md-12">
                    <p className="title">Q: {faq.question}</p>
                    <p className="normal-text">A: {faq.answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Faq;
