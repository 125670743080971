import Joi from "joi-browser";

export const validate = (schemaObject, Obj) => {
  //returns the array of errros by validating schemaObject with Obj
  const ConsolidatedErrors = [];
  const schema = Joi.object(schemaObject).options({ abortEarly: false });
  const result = schema.validate(Obj);
  if (result.error) {
    const errors = result.error.details;
    for (let error of errors) {
      ConsolidatedErrors.push(error.message);
    }
  }

  return ConsolidatedErrors;
};

export default validate;
