import React, { Component } from "react";
import donationServices from "../../services/donationServices";
import { Link } from "react-router-dom";
import icons from "../common/icons";
import getDateString from "../common/commonFunction";
import CustomAlert from "../common/customAlert";
import Loader from "../../components/common/loader";

class VolunteerAcceptedDonations extends Component {
  state = {
    data: [],
    loading: true,
  };

  componentDidMount = async () => {
    const { data } = await donationServices.getAcceptedDonationsForVolunteer();
    this.setState({ data, loading: false });
  };

  displayReservedDonations = () => {
    const { data } = { ...this.state };
    return (
      data.length > 0 &&
      data.map((donation, key) => {
        const { address, city, state, zipcode } = donation;
        return (
          <Link key={key} to={"/donation/details/" + donation.id}>
            <div className="col-md-12 mb-10 box-style-4 volunteer-active">
              <div className="section1 mb-10">
                <div className="text-container col-md-12">
                  <p className="text-white volunteer-head">
                    {donation?.food_description}
                  </p>
                  <div
                    className="text-white"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <p>
                        <i
                          style={{ marginRight: "10px" }}
                          className="fa fa-map-marker"
                          aria-hidden="true"
                        ></i>
                        Pickup Location
                      </p>
                      <p>{donation.pickup_location}</p>
                    </div>
                    <div>
                      <p>
                        <i
                          style={{ marginRight: "10px" }}
                          className="fa fa-map-marker"
                          aria-hidden="true"
                        ></i>
                        delivery Location
                      </p>
                      <p>
                        {address},{city},{state},{zipcode}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <CustomAlert status={donation.status} charityBg={true} />
            </div>
          </Link>
        );
      })
    );
  };

  render() {
    return this.state.loading ? <Loader /> : this.displayReservedDonations();
  }
}

export default VolunteerAcceptedDonations;
