import React from "react";
import logo from "../../images/logo-footer.png";
import "./footer.scss";
const Footer = () => {
  return (
    <React.Fragment>
      <div className="spacer"></div>
      <footer className="footer">
        <div className="container">
          <div className="row footer-bottom">
            <div className="col-md-6 col-xs-12">
              <ul className="type2">
                <li>
                  <i className="fa fa-envelope"></i>
                </li>
                <li>
                  <i className="fa fa-facebook-f"></i>
                </li>
                <li>
                  <i className="fa fa-twitter"></i>
                </li>
                <li>
                  <i className="fa fa-instagram"></i>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-xs-12">
              <p className="pull-right mb-0">
                A© 2021 WasteNoFood.org. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
