import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import CustomAlert from "./common/customAlert";
import avatar from "../images/avatar.png";

class DonationSuccess extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-1">
            <NavLink to="/home" className="btn-style-2 btn mt-20">
              <i className="fa fa-chevron-left"></i>
            </NavLink>
          </div>
          <div className="col-md-7 title-1 p-10">
            Donation <br /> Details{" "}
          </div>

          <div className="col-md-4">
            <NavLink to="" className="btn-style-2 btn pull-right mt-20 ml-10">
              <i className="fa fa-share-alt"></i> Share
            </NavLink>
          </div>
        </div>
        <hr className="line-1" />
        <CustomAlert
          status="success"
          text="Your donation request accepted"
          icon="check"
        />
        <div className="panel panel-default box-style-2 mt-20">
          <div className="panel-body">
            <div className="col-md-7">
              <h5>Enter Pickup Code </h5>
              <p className="text-muted">
                Enter the pick up code shown in volunteer’s phone to confirm the
                pick up
              </p>
            </div>
            <div className="col-md-2">
              <input type="text" className="form-control mt-10 pin-no" />
              <input type="text" className="form-control mt-10 pin-no" />
              <input type="text" className="form-control mt-10 pin-no" />
              <input type="text" className="form-control mt-10 pin-no" />
            </div>
            <div className="col-md-3">
              <NavLink
                to=""
                className="btn-style-2 btn btn-primary pull-right mt-10 ml-10"
              >
                {" "}
                Conform Pickup
              </NavLink>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="grid-container donation-details ">
            <div className="box-style-2">
              <div className="section1">
                <div className="left col-md-12">
                  <p>500 Avocado Sandwiches and Cheesecakes</p>
                </div>
              </div>
              <hr className="line-2" />
              <div className="section2">
                <div className="left col-md-12">
                  <p className="text-muted">
                    <span
                      className="glyphicon glyphicon-time mt-4"
                      aria-hidden="true"
                    ></span>{" "}
                    Pickup Date & Time
                  </p>
                  <p className="normal-text">
                    Monday, June 17 - Between 06 to 07:00PM
                  </p>
                </div>
              </div>
              <hr className="line-2" />
              <div className="section2">
                <div className="left col-md-12">
                  <p className="text-muted">
                    <span
                      className="glyphicon glyphicon-send mt-4"
                      aria-hidden="true"
                    ></span>{" "}
                    Pickup Location
                  </p>
                  <p className="normal-text">
                    576 E Santa Clara St, San Jose, CA{" "}
                  </p>
                </div>
              </div>
              <hr className="line-2" />
              <div className="section2">
                <div className="left col-md-12">
                  <p className="text-muted">
                    <span
                      className="glyphicon glyphicon-duplicate mt-4"
                      aria-hidden="true"
                    ></span>{" "}
                    Specific Instructions
                  </p>
                  <p className="normal-text">
                    Please come to the back door and ask for John to pickup the
                    food
                  </p>
                </div>
              </div>
            </div>
            <div className="box-style-2 food-details">
              <div className="section1">
                <div className="left col-md-12">
                  <p>Food Items</p>
                </div>
              </div>
              <hr className="line-2" />
              <div className="section2">
                <div className="media">
                  <div className="media-left">
                    <a href="#">
                      <img className="user-image" alt="logo" src={avatar} />
                    </a>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">Impossible Burger</h4>
                    <p className="text-muted">Qty: 175</p>
                  </div>
                </div>
              </div>
              <hr className="line-2" />
              <div className="section2">
                <div className="media">
                  <div className="media-left">
                    <a href="#">
                      <img className="user-image" alt="logo" src={avatar} />
                    </a>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">Cheesecakes</h4>
                    <p className="text-muted">Qty: 175</p>
                  </div>
                </div>
              </div>
              <hr className="line-2" />
              <div className="section2">
                <div className="media">
                  <div className="media-left">
                    <a href="#">
                      <img className="user-image" alt="logo" src={avatar} />
                    </a>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">Russian Fruit Salad</h4>
                    <p className="text-muted">Qty: 175</p>
                  </div>
                </div>
              </div>
              <hr className="line-2" />
              <div className="section2">
                <div className="media">
                  <div className="media-left">
                    <a href="#">
                      <img className="user-image" alt="logo" src={avatar} />
                    </a>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">Cheesecakes</h4>
                    <p className="text-muted">Qty: 175</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-style-2">
              <div className="section1">
                <div className="left col-md-12">
                  <p>Donation Accepted by</p>
                </div>
              </div>
              <hr className="line-2" />
              <div className="section2">
                <div className="media">
                  <div className="media-left">
                    <a href="#">
                      <img className="user-image" alt="logo" src={avatar} />
                    </a>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">Work2Future Foundation</h4>
                    <p className="text-muted">6 miles</p>
                  </div>
                </div>
              </div>
              <div className="section2">
                <div className="left col-md-12">
                  <p className="text-muted">
                    <span
                      className="glyphicon glyphicon-send mt-4"
                      aria-hidden="true"
                    ></span>{" "}
                    Address
                  </p>
                  <p className="normal-text">
                    449 E Santa Clara St, San Jose, CA 95113{" "}
                  </p>
                </div>
              </div>
              <div className="section2">
                <div className="left col-md-12">
                  <p className="text-muted">
                    <span
                      className="glyphicon glyphicon-user mt-4"
                      aria-hidden="true"
                    ></span>{" "}
                    Contact Person
                  </p>
                  <p className="normal-text">John Doe</p>
                </div>
              </div>
              <div className="section2">
                <div className="left col-md-12">
                  <p className="text-muted">
                    <span
                      className="glyphicon glyphicon-phone mt-4"
                      aria-hidden="true"
                    ></span>{" "}
                    Phone
                  </p>
                  <p className="normal-text">+14082983030</p>
                </div>
              </div>
              <div className="section2">
                <div className="left col-md-12">
                  <p className="text-muted">
                    <span
                      className="glyphicon glyphicon-envelope mt-4"
                      aria-hidden="true"
                    ></span>{" "}
                    Email
                  </p>
                  <p className="normal-text">hellojohndoe@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DonationSuccess;
