import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from 'react-router-dom';

const ToastNotification = (props) => {
 
  const history = useHistory();

  const redirect = () => {
    history.push('/login');
  }
  useEffect(() => {
    toast(props.message);
    if(props.redirect)
   redirect()
  });
  return (
    <div>
      <ToastContainer />
    </div>
  );
};

export default ToastNotification;
