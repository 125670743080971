import React, { Component } from "react";
import CustomAlert from "../common/customAlert";
import icons from "../common/icons";
import "./charityActiveDonation.scss";
import * as donationService from "../../services/donationServices";
import getDateString from "../common/commonFunction";
import Loader from "../common/loader";
import { Link } from "react-router-dom";
import { userDetails } from "../../services/userServices";
import Popup from "../common/popup";

class CharityActiveDonation extends Component {
  state = {
    loading: true,
    data: null,
    donationsToAccept: null,
    miles: null,
    milesInPopup: null,
    close: true,
  };

  async componentDidMount() {
    const donations = await donationService.getActiveDonations();
    const miles = userDetails.notification_range;
    const donationsToAccept = await donationService.getActiveDonationsByMiles(
      miles
    );
    this.setState({
      data: donations,
      loading: false,
      donationsToAccept,
      miles,
      milesInPopup: miles,
    });
  }

  displayReservedDonations = () => {
    const { data } = { ...this.state };
    return (
      data &&
      data.map((donation, key) => {
        return (
          <Link key={key} to={"/donation/details/" + donation.id}>
            <div className="col-md-12 mb-10 box-style-4">
              <div className="section1 mb-10">
                <div className="text-container col-md-12">
                  <p className="text-white">{donation?.food_description}</p>
                  <p className="text-white">
                    <img
                      className="mr-10"
                      src={icons.charityClock}
                      alt="icon"
                    />
                    {getDateString(donation?.pickup_time)}
                  </p>
                </div>
              </div>
              <CustomAlert status={donation?.status} charityBg={true} />
            </div>
          </Link>
        );
      })
    );
  };

  displayActiveDonations = () => {
    let { donationsToAccept, miles, close, milesInPopup } = { ...this.state };
    donationsToAccept = donationsToAccept.filter(
      (donation) => donation.distance <= miles
    );
    return (
      <>
        <div className="col-md-12 p0">
          <Popup
            // size="250px"
            title="Filter by"
            buttonTrigger={
              <span
                onClick={() => this.setState({ close: !close })}
                style={{ display: "inline" }}
              >
                <span style={{ display: "inline" }}>
                  {donationsToAccept?.length} Active Donations Within &nbsp;
                </span>
                <span className="badge badge-warning">
                  {milesInPopup} miles
                </span>
              </span>
            }
            close={close}
            body={
              <>
                <p>Distance - {milesInPopup} miles</p>
                <input
                  onChange={(e) => this.onMilesChange(e.target.value)}
                  type="range"
                  min="0"
                  max={userDetails.notification_range}
                  value={milesInPopup}
                />
                <div className="float-right">
                  <button
                    onClick={() => this.setMiles()}
                    className="btn-style-1"
                  >
                    Apply
                  </button>
                </div>
              </>
            }
          />
        </div>
        {donationsToAccept &&
          donationsToAccept.map((donation, key) => {
            return (
              <Link key={key} to={"/donation/details/" + donation.id}>
                <div className="col-md-12 active-donation-box mt-10">
                  <div className="media">
                    <img
                      className="mr-2 avatar-box"
                      src={icons.defaultFoodIcon}
                      alt="Generic placeholder image"
                    />
                    <div className="media-body row">
                      <div className="col-10">
                        <h5 className="mt-0 mb-0 donation-title">
                          {donation?.food_description}
                        </h5>
                        <span className="text-muted">
                          <img src={icons.clock} alt="icon" /> Pickup Date &
                          Time
                        </span>{" "}
                        <span className="pickup-time">
                          {getDateString(donation?.pickup_time)}
                        </span>
                      </div>
                      <div className="col-2">
                        <span className="text-muted pull-right mt-2 miles">
                          {donation?.distance} Miles
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
      </>
    );
  };

  onMilesChange = (value) => {
    this.setState({ milesInPopup: value });
  };

  setMiles = () => {
    const data = { ...this.state };
    this.setState({ miles: data.milesInPopup, close: !data.close });
  };

  render() {
    const { loading } = { ...this.state };
    return (
      <React.Fragment>
        {loading ? (
          <Loader />
        ) : (
          <div className="row">
            <div className="container charity-dashboard">
              {this.displayReservedDonations()}
              {this.displayActiveDonations()}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default CharityActiveDonation;
