import React, { Component } from "react";
import CustomAlert from "./../common/customAlert";
import { NavLink, Link } from "react-router-dom";
import * as DonationServices from "../../services/donationServices";
import { getUserType } from "../../services/userServices";
import getDateString from "../common/commonFunction.js";
import "./donationHistory.scss";
import Loader from "../common/loader";
import clock from "../../images/icons/clock.png";
import Export from "../common/Export/Export";

class DonationHistory extends Component {
  state = {
    loading: true,
    NotificationMessage: "hello",
    donationHistory: [],
  };

  componentDidMount = async () => {
    let donations = [];
    let donation = null;
    if (getUserType() === "donor")
      donations = await DonationServices.getDonorDonationsHistory();
    else if (getUserType() === "charity")
      donations = await DonationServices.getCharityDonationsHistory();
    else if (getUserType() === "volunteer")
      donations = await DonationServices.getDonationsHistoryForVolunteer();
    await this.setState({ donationHistory: donations, loading: false });
  };

  donationsHistoryData = () => {
    const { donationHistory } = { ...this.state };
    return donationHistory?.map((donation, key) => {
      return (
        <Link key={key} to={"/donation/details/" + donation.id}>
          <div className="box-style-3 mb-20 p0">
            <div className="section1">
              <div className="text-container p-2">
                <p className="normal-text">{donation?.food_description}</p>
                {/* <p className="text-muted"><span className="glyphicon glyphicon-time mt-4" aria-hidden="true"></span> Pickup Date & Time</p> */}
                <p className="text-muted">
                  <img className="mr-2" src={clock} alt={clock} />
                  {getDateString(donation?.pickup_time)}
                </p>
              </div>
            </div>
            <CustomAlert
              status={donation.status}
              text={donation.status}
              icon="send"
              font="12px"
            />
          </div>
        </Link>
      );
    });
  };

  render() {
    const { loading, NotificationMessage } = { ...this.state };
    return loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        <div className="row mt-20 mb-20">
          <div className="col title-1 mt-15">Donation History</div>
          <div className="col">
            <Export />
          </div>
          <hr className="line-1" />
        </div>
        <div className="history-container row">
          {this.donationsHistoryData()}
        </div>
      </React.Fragment>
    );
  }
}

export default DonationHistory;
