import "./styles.scss";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import Header from "./components/header/header";
import ControlPanel from "./components/ControlPanel";
import Login from "./components/login/Login";
import Signup from "./components/signup/Signup";
import jwt_decode from "jwt-decode";
import { Redirect, Route } from "react-router-dom";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";

export const validateUser = () => {
  let decoded = "";
  const jwt = localStorage.getItem("token");
  if (jwt) return jwt_decode(jwt);
  else return jwt;
};

const LoggedIn = () => {
  return <ControlPanel />;
};

const NotLoggedIn = () => {
  return (
    <div className="col-md-12 page-content">
      <switch>
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgotPassword" component={ForgotPassword} />
        <Redirect to="/login" />
      </switch>
    </div>
  );
};

function App() {
  return (
    <div id="wrapper">
      <Header />
      {validateUser() && LoggedIn()}
      {!validateUser() && NotLoggedIn()}
    </div>
  );
}

export default App;
