import React, { Component } from "react";
import avatar from "../../images/avatar.png";
import { validateUser } from "../../App";
import { Navbar, NavDropdown, Row, Col, Image } from "react-bootstrap";
import { NavLink, Link, useHistory } from "react-router-dom";
import {
  getDisplayName,
  getUserDisplayIcon,
} from "../../services/userServices";
import "./header.scss";
import logo from "../../images/wnf_logo.png";

const Header = () => {
  let history = useHistory();
  const validUser = validateUser();
  const username = getDisplayName();
  const icon = getUserDisplayIcon();
  return (
    <header className="header-style-1">
      <div className="container">
        {validUser && (
          <Navbar>
            <Navbar.Brand>
              <Link to="/home">
                <Row>
                  <Col className={"logoContainer"}>
                    <Image src={logo} className={"logo"} />
                  </Col>
                </Row>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="profile-details">
              <NavDropdown
                title={
                  <React.Fragment>
                    <Navbar.Text>
                      <b>{username}</b>
                    </Navbar.Text>
                    <img src={icon ? icon : avatar} className="avatar ml-2" />
                  </React.Fragment>
                }
                id="collasible-nav-dropdown p0"
              >
                <NavDropdown.Item onClick={() => history.push("/profile")}>
                  <i className="fa fa-user mr-2"></i>Profile
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push("/settings")}>
                  <i className="fa fa-cog mr-2"></i>Settings
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <NavLink to="/logout">
                    <i className="fa fa-sign-out mr-2"></i>Logout
                  </NavLink>
                </NavDropdown.Item>
              </NavDropdown>
            </Navbar.Collapse>
          </Navbar>
        )}
        {!validUser && (
          <Navbar>
            <Navbar.Brand href="#home">
              <Row>
                <Col className={"logoContainer"}>
                  <Image src={logo} className={"logo"} />
                </Col>
              </Row>
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
              <NavLink to="/login" className="btn btn-primary mr-10">
                Login
              </NavLink>
              <NavLink to="/signup" className="btn btn-primary">
                Signup
              </NavLink>
            </Navbar.Collapse>
          </Navbar>
        )}
      </div>
    </header>
  );
};

export default Header;
