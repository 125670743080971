import React, { Component } from "react";
import avatar from "../../images/avatar.png";
import "./volunteerActiveDonation.scss";
import { Link } from "react-router-dom";
import icons from "../common/icons";
import { getAllDonationsForVolunteer } from "../../services/donationServices";
import Popup from "../common/popup";
import { userDetails } from "../../services/userServices";

class VolunteerActiveDonation extends Component {
  state = {
    donationDetails: [],
    miles: null,
    close: true,
    milesInPopup: null,
  };
  componentDidMount = async () => {
    const miles = userDetails.notification_range;
    const donationDetails = await getAllDonationsForVolunteer(miles);
    const milesInPopup = miles;
    this.setState({ donationDetails, miles, milesInPopup });
  };

  onMilesChange = (value) => {
    this.setState({ milesInPopup: value });
  };
  setMiles = async () => {
    const { close, milesInPopup } = { ...this.state };
    const donationDetails = await getAllDonationsForVolunteer(milesInPopup);
    this.setState({ donationDetails, miles: milesInPopup, close: !close });
  };

  render() {
    const donations = [...this.state.donationDetails];
    const { close, milesInPopup, miles, donationDetails } = { ...this.state };
    return (
      <React.Fragment>
        <Popup
          // size="250px"
          title="Filter by"
          buttonTrigger={
            <span
              onClick={() =>
                this.setState({ close: !close, milesInPopup: miles })
              }
              style={{ display: "inline" }}
            >
              <span style={{ display: "inline" }}>
                {donationDetails?.length} Active Donations Within &nbsp;
              </span>
              <span className="badge badge-warning">{miles} miles</span>
            </span>
          }
          close={close}
          body={
            <>
              <p>Distance - {milesInPopup} miles</p>
              <input
                onChange={(e) => this.onMilesChange(e.target.value)}
                type="range"
                min="0"
                max={userDetails.notification_range}
                value={milesInPopup}
              />
              <div className="float-right">
                <button onClick={() => this.setMiles()} className="btn-style-1">
                  Apply
                </button>
              </div>
            </>
          }
        />
        {/* <h3>
          {donations?.length} Active Donations Within
          <select
            value={this.state.miles}
            onChange={(e) => this.onMilesChange(e.target.value)}
            className="ml-10 select-miles"
            name="miles"
          >
            <option value="10">10 Miles</option>
            <option value="20">20 Miles</option>
            <option value="30">30 Miles</option>
            <option value="40">40 Miles</option>
            <option value="600">600 Miles</option>
          </select>
        </h3> */}
        <div className="volunteer">
          {donations.map((donation, key) => (
            <Link key={key} to={"donation/details/" + donation.id}>
              <div className="vol-box">
                <div className="media">
                  <div className="media-body row">
                    <div className="col-12">
                      <h5 className="mt-0 mb-0">{donation.food_description}</h5>
                      <span className="text-muted"></span>
                    </div>
                  </div>
                </div>
                <ul className="type-3">
                  <li className="address"> {donation.address}</li>
                  <li className="address">{donation.pickup_location}</li>
                </ul>
              </div>
            </Link>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default VolunteerActiveDonation;
