import moment from "moment";

const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
export const AMPMTime = (DBdate) => {
  const date = new Date(DBdate);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

const getDateString = (date) => {
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const StringDate = new Date(date);
  return (
    weekdays[StringDate.getDay()] +
    ", " +
    months[StringDate.getMonth()] +
    " " +
    StringDate.getDate() +
    ", " +
    formatAMPM(StringDate) +
    " "
  );
};
const twoDigitsFormat = (number) => {
  return number < 10 ? "0" + number : number;
};

export const setDateTime = (dateTime) => {
  const StringDate = new Date();
  const year = dateTime.date.slice(0, 4);
  const month = dateTime.date.slice(5, 7);
  const day = dateTime.date.slice(8, 10);
  const hours = dateTime.time.slice(0, 2);
  const mins = dateTime.time.slice(3, 5);
  StringDate.setFullYear(year);
  StringDate.setMonth(month - 1);
  StringDate.setDate(day);
  StringDate.setHours(hours);
  StringDate.setMinutes(mins);
  return StringDate.toString();
};

export const getDateTime = (dateTime) => {
  const StringDate = new Date(dateTime);

  const time = [
    twoDigitsFormat(StringDate.getHours()),
    twoDigitsFormat(StringDate.getMinutes()),
  ];

  const date = [
    twoDigitsFormat(StringDate.getFullYear()),
    twoDigitsFormat(StringDate.getMonth() + 1),
    twoDigitsFormat(StringDate.getDate()),
  ];

  return {
    date: date.join("-"),
    time: time.join(":"),
  };
};

export default getDateString;
