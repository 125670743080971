import React, { Component } from "react";
import { NavLink, Link, Route } from "react-router-dom";
import CustomAlert from "./common/customAlert";
import { apiUrl } from "../config.json";
import http from "../services/httpService";
import donationServices from "../services/donationServices";
import getDateString from "./common/commonFunction.js";
import Loader from "./common/loader";
import { getUserType } from "../services/userServices";
import Popup from "./common/popup";
import Mail from "./common/Mail";
import icons from "./common/icons";
import { imageURL } from "../config.json";
import CancelDonation from "./CancelDonation/cancelDonation";
import AddDonation from "./addDonation/AddDonation";
import { Switch } from "react-router-dom";
import ToastNotification from "./common/toastNotification";

class DonationDetails extends Component {
  state = {
    pickUpCode: "",
    loading: true,
    donationDetail: null,
    charities: null,
    selfPickup: true,
    closePopUp: false,
    donorInfo: null,
    extendTimeInMinutes: null,
    error: {
      status: null,
      message: null,
    },
  };

  componentDidMount = async (props) => {
    const donationId = this.props?.match?.params?.id;
    let donorInfo = null;
    if (donationId) {
      const donationDetail = await donationServices.getDonationData(donationId);
      const charities = donationDetail?.designated_charities;
      if (getUserType() === "charity" && donationDetail.status === "waiting")
        donorInfo = await this.getDonorDetails(donationDetail);
      if (getUserType() === "charity" && donationDetail.status !== "waiting")
        donorInfo = donationDetail.donor;
      this.setState({ donationDetail, loading: false, charities, donorInfo });
    }
  };

  getDonorDetails = async (donationDetail) => {
    const apiEndpoint = apiUrl + "/donors/";
    let donorId = donationDetail?.donor_id;
    const { data: donorDetails } = await http.get(apiEndpoint + donorId);
    return donorDetails.data;
  };

  getCharityDetails = async (donationDetail) => {
    const apiEndpoint = apiUrl + "/charities/";

    let charityIds = donationDetail?.designated_charities;

    let CharityNames = [];
    if (charityIds.length > 0) {
      for (let charity of charityIds) {
        const { data: charityDetails } = await http.get(apiEndpoint + charity);

        CharityNames.push({
          charity_name: charityDetails.data?.charity_name,
          picture: charityDetails.data?.picture,
          address: charityDetails.data?.address,
          contact_person: charityDetails.data?.contact_person,
          phone: charityDetails.data?.phone,
          email: charityDetails.data?.email,
          charity_id: charityDetails.data?.charity_id,
        });
      }
    }
    return CharityNames;
  };

  displayDonationDetail = () => {
    const { donationDetail } = { ...this.state };
    return (
      <Link to={{ pathname: "/donation/donation-details" }} property="hello">
        <div className="box-style-2">
          <div className="section1">
            <div className="left col-md-12 boldFont">
              <p>{donationDetail?.food_description}</p>
            </div>
          </div>
          <hr className="line-2" />
          <div className="section2">
            <div className="left col-md-12">
              <p className="text-muted">
                <img className="mr-2" src={icons.clock} alt="icon" />
                Pickup Date & Time
              </p>
              <p className="normal-text boldFont">
                {getDateString(donationDetail?.pickup_time)}
              </p>
            </div>
          </div>
          <hr className="line-2" />
          <div className="section2">
            <div className="left col-md-12">
              <p className="text-muted">
                <img className="mr-2" src={icons.send} alt="icon" /> Pickup
                Location
              </p>
              <p className="normal-text boldFont">
                {donationDetail?.pickup_location}{" "}
              </p>
            </div>
          </div>
          <hr className="line-2" />
          <div className="section2">
            <div className="left col-md-12">
              <p className="text-muted">
                <img className="mr-2" src={icons.instruction} alt="icon" />
                Specific Instructions
              </p>
              <p className="normal-text boldFont">
                {donationDetail?.pickup_instruction}
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  displayFoodDetails = () => {
    const { donationDetail } = { ...this.state };
    return (
      <div className="box-style-2 food-details">
        <div className="section1">
          <div className="left col-md-12">
            <p>Food Items</p>
          </div>
        </div>
        {donationDetail?.food_items.map((food, key) => {
          return (
            <div key={key}>
              <hr className="line-2" />

              <div className="section2">
                <div className="media">
                  <div className="media-left">
                    <a
                      href={
                        food.picture !== "null" && food.picture !== ""
                          ? imageURL + food.picture
                          : icons.defaultFoodIcon
                      }
                    >
                      <img
                        className="user-image"
                        alt="logo"
                        src={
                          food.picture !== "null" && food.picture !== ""
                            ? imageURL + food.picture
                            : icons.defaultFoodIcon
                        }
                      />
                    </a>
                  </div>
                  <div className="media-body">
                    <h4 className="media-heading">{food.foodname}</h4>
                    <p className="text-muted">Qty: {food.quantity}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  returnCharityAddress = (charityDetail) => {
    const { address, city, state, zipcode } = charityDetail;
    return (
      <p className="normal-text">
        {address},{city},{state},{zipcode}
      </p>
    );
  };

  displayDonatedBy = () => {
    const { donorInfo } = { ...this.state };

    return (
      donorInfo && (
        <div className="box-style-2 donated-by">
          <div className="section1">
            <div className="left col-md-12 p-2">
              <p>Donated by</p>
            </div>
          </div>

          <hr className="line-2" />
          <div className="section2">
            <div className="media">
              <img
                className="mr-2 profile-image"
                src={
                  donorInfo.picture === "null" || donorInfo.picture === null
                    ? icons.defaultProfileIcon
                    : imageURL + donorInfo.picture
                }
                alt="avatar"
              />
              <div className="media-body">
                <h4 className="media-heading">{donorInfo.donor_name}</h4>
                {donorInfo.distance >= 0 && (
                  <p className="text-muted"> {donorInfo.distance} miles</p>
                )}
              </div>
            </div>
          </div>

          <div className="section2">
            <div className="left col-md-12">
              <p className="text-muted">
                <img className="mr-2" src={icons.address} alt="icon" />
                Address
              </p>
              <p className="boldFont">{this.returnCharityAddress(donorInfo)}</p>
            </div>
          </div>
          <div className="section2">
            <div className="left col-md-12">
              <p className="text-muted ">
                <img className="mr-2" src={icons.contact} alt="icon" /> Contact
                Person
              </p>
              <p className="normal-text boldFont">{donorInfo.contact_person}</p>
            </div>
          </div>
          <div className="section2">
            <div className="left col-md-12">
              <p className="text-muted">
                <img className="mr-2" src={icons.phone} alt="icon" /> Phone
              </p>
              <p className="normal-text boldFont">{donorInfo.users[0].phone}</p>
            </div>
          </div>
          <div className="section2">
            <div className="left col-md-12">
              <p className="text-muted">
                <img className="mr-2" src={icons.email} alt="icon" /> Email
              </p>
              <Mail to={donorInfo.users[0].email} />
            </div>
          </div>
        </div>
      )
    );
  };

  displayDesignateCharities = () => {
    const { charity } = { ...this.state.donationDetail };
    return (
      <div className="box-style-2 food-details">
        <div className="section1">
          <div className="left col-md-12">
            <p>Reserved by</p>
          </div>
        </div>
        {
          <React.Fragment>
            <hr className="line-2" />
            <div className="section2">
              <div className="media">
                <div className="media-left">
                  <a
                    href={
                      charity.picture
                        ? imageURL + charity.picture
                        : icons.defaultProfileIcon
                    }
                  >
                    <img
                      className="user-image"
                      alt="logo"
                      src={
                        charity.picture
                          ? imageURL + charity.picture
                          : icons.defaultProfileIcon
                      }
                    />
                  </a>
                </div>
                <div className="media-body">
                  <h4 className="media-heading">{charity.charity_name}</h4>
                  <p className="text-muted">{charity.distance} Miles</p>
                </div>
              </div>
            </div>
            <div className="section2">
              <div className="left col-md-12">
                <p className="text-muted">
                  <img className="mr-2" src={icons.address} alt="icon" />
                  Address
                </p>
                <p className="normal-text boldFont">{charity.address}</p>
              </div>
            </div>
            <div className="section2">
              <div className="left col-md-12">
                <p className="text-muted">
                  <img className="mr-2" src={icons.contact} alt="icon" />
                  Contact Person
                </p>
                <p className="normal-text boldFont">
                  {charity.contact_person}{" "}
                </p>
              </div>
            </div>
            <div className="section2">
              <div className="left col-md-12">
                <p className="text-muted">
                  <img className="mr-2" src={icons.phone} alt="icon" />
                  Phone
                </p>
                <p className="normal-text boldFont">{charity.users[0].phone}</p>
              </div>
            </div>
            <div className="section2">
              <div className="left col-md-12">
                <p className="text-muted">
                  <img className="mr-2" src={icons.email} alt="icon" />
                  Email
                </p>
                <p className="boldFont">
                  {" "}
                  <Mail to={charity.users[0].email} />
                </p>
              </div>
            </div>
          </React.Fragment>
        }
      </div>
    );
  };

  displayDonorDesignateCharities = () => {
    const charities = [...this.state.charities];
    const donationDetail = { ...this.state.donationDetail };
    if (donationDetail.charity_id !== null)
      return this.displayDesignateCharities();
    if (charities.length > 0 && donationDetail.charity_id === null) {
      return (
        <div className="box-style-2 food-details">
          <div className="section1">
            <div className="left col-md-12">
              <p>Designated Charities</p>
            </div>
          </div>
          {charities.map((charity, key) => {
            return (
              <div key={key}>
                <hr className="line-2" />
                <div className="section2">
                  <div className="media">
                    <div className="media-left">
                      <a
                        href={
                          charity.picture
                            ? imageURL + charity.picture
                            : icons.defaultProfileIcon
                        }
                      >
                        <img
                          className="user-image"
                          alt="logo"
                          src={
                            charity.picture
                              ? imageURL + charity.picture
                              : icons.defaultProfileIcon
                          }
                        />
                      </a>
                    </div>
                    <div className="media-body">
                      <h4 className="media-heading">{charity.charity_name}</h4>
                      <p className="text-muted">{charity.distance} Miles</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  displayVolunteerDetails = () => {
    const { volunteer } = { ...this.state.donationDetail };
    return (
      <div className="box-style-2 food-details mt-3">
        <div className="section1">
          <div className="left col-md-12">
            <p>Food will be delivered by</p>
          </div>
        </div>
        <hr className="line-2" />
        <div className="section2">
          <div className="media">
            <div className="media-left">
              <a
                href={
                  volunteer.picture
                    ? imageURL + volunteer.picture
                    : icons.defaultProfileIcon
                }
              >
                <img
                  className="user-image"
                  alt="logo"
                  src={
                    volunteer.picture
                      ? imageURL + volunteer.picture
                      : icons.defaultProfileIcon
                  }
                />
              </a>
            </div>
            <div className="media-body">
              <h4 className="media-heading">{volunteer.volunteer_name}</h4>
              <Mail to={volunteer.users[0].email} />
            </div>
          </div>
        </div>
        <div className="section2">
          <div className="left col-md-12">
            <p className="text-muted">
              <img className="mr-2" src={icons.address} alt="icon" />
              Address
            </p>
            <p className="normal-text">{volunteer.address}</p>
          </div>
        </div>
        <div className="section2">
          <div className="left col-md-12">
            <p className="text-muted">
              <img className="mr-2" src={icons.phone} alt="icon" />
              Phone
            </p>
            <p className="normal-text">{volunteer.users[0].phone}</p>
          </div>
        </div>
      </div>
    );
  };

  deleteDonation = async () => {
    const donationId = this.props?.match?.params?.id;
    const endPoint = apiUrl + "/donations/" + donationId;
    const response = await http.delete(endPoint);
    alert("cancelled donation " + donationId);
  };

  onChange = (inputField, inputValue) => {
    const { pickUpCode } = { ...this.state };
    const code = inputValue;
    this.setState({ pickUpCode: code });
  };

  confirmPickUp = async () => {
    const response = await donationServices.confirmDonation(
      this.props?.match?.params?.id,
      this.state.pickUpCode
    );
    if (response.data.status === "success") {
      this.props.sendNotification("Donation has been picked up!");
      return this.props.history.push("/donation-history");
    } else {
      return this.props.sendNotification(
        response?.data?.message || "An error Occurred, please retry!"
      );
    }

    // const error = { ...this.state.error };
    // error.status = true;
    // error.message = response.message;
    // this.setState({ error });
  };

  displayPickUpCodeArea = () => {
    const { donationDetail, pickUpCode } = { ...this.state };
    if (
      (donationDetail.status === "reserved" ||
        donationDetail.status === "accepted") &&
      getUserType() === "donor"
    )
      return (
        <React.Fragment>
          <div className="panel panel-default box-style-2 mt-20">
            <div className="panel-body row">
              <div className="col-md-7">
                <h5>Enter Pickup Code </h5>

                {!donationDetail.is_self_pickup && (
                  <p className="text-muted">
                    Enter the pick up code shown in volunteer’s phone to confirm
                    the pick up
                  </p>
                )}
                {donationDetail.is_self_pickup && (
                  <p className="text-muted">
                    Enter the pick up code shown to confirm the pick up
                  </p>
                )}
              </div>
              <div className="col-md-2">
                <input
                  type="text"
                  onChange={(e) =>
                    this.onChange("pickUpCode", e.currentTarget.value)
                  }
                  className="form-control mt-10 pin-no"
                  placeholder="0000"
                  value={pickUpCode}
                />
              </div>
              <div className="mt-10 mb-20 col-md-3">
                <button
                  onClick={() => this.confirmPickUp()}
                  className="btn btn-primary pull-right  ml-10"
                >
                  Confirm Pickup
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    if (
      (donationDetail.status === "reserved" ||
        donationDetail.status === "accepted") &&
      getUserType() === "charity"
    )
      return (
        <React.Fragment>
          <div className="panel panel-default box-style-2 mt-20">
            <div className="panel-body row">
              <div className="col-md-10">
                <h5>Your Pickup Code </h5>
                <p className="text-muted">
                  Show pick up code to the donor to confirm pickup
                </p>
              </div>
              <div className="passCode col-md-2">
                <h5>{donationDetail.passcode}</h5>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
  };

  AcceptDonation = async (e) => {
    const { donationDetail, selfPickup } = { ...this.state };
    e.preventDefault();
    const response = await donationServices.AcceptDonation(
      donationDetail.id,
      selfPickup
    );
    if (response === "success") {
      this.props.sendNotification("Donation Accepted!");
      this.props.history.push("/donation");
    }
  };

  cancelDonation = async (id, comments) => {
    const response = await donationServices.cancelDonation(id, comments);
    if (response === "success") this.props.history.push("/donation-history");
  };

  resendDonationReq = async (id) => {
    const response = await donationServices.resendRequest(id);
    if (response === "success") this.props.history.push("/donation");
  };

  showAcceptDonationButton = () => {
    return (
      <>
        <div className="col-md-3"></div>
        <Popup
          title="Select Pickup Type"
          body={
            <>
              <input
                onChange={() => this.setState({ selfPickup: true })}
                type="radio"
                name="pickupType"
                value="self"
                id="self"
                className="mr-2"
                checked={this.state.selfPickup ? true : false}
              />
              <label for="self">Self Pickup</label>
              <br />
              <input
                onChange={() => this.setState({ selfPickup: false })}
                type="radio"
                name="pickupType"
                value="Volunteer"
                id="Volunteer"
                className="mr-2"
                checked={!this.state.selfPickup ? true : false}
              />
              <label for="Volunteer">Volunteer Pickup</label>
              <div>
                <button className="btn-style-2 btn mt-20 ml-10">Cancel</button>
                <button
                  onClick={(e) => this.AcceptDonation(e)}
                  className="btn-style-2 btn mt-20 ml-10"
                >
                  Accept
                </button>
              </div>
            </>
          }
          buttonTrigger={
            <button className="btn-style-2 btn pull-right mt-20 ml-10">
              Accept Donation
            </button>
          }
        />
      </>
    );
  };

  receiveDonation = async () => {
    const response = await donationServices.donationReceived(
      this.props?.match?.params?.id
    );
    if (response?.status === "success")
      this.props.sendNotification("Donation Received!", true);
    if (response.status !== "success")
      this.props.sendNotification("An error Occurred, Please retry!", true);
  };

  extendTime = async () => {
    const response = await donationServices.extendTime(
      this.props?.match?.params?.id,
      this.state.extendTimeInMinutes
    );
    if (response?.status === "success")
      this.props.sendNotification("Time Extended", true);
    if (response.status !== "success")
      this.props.sendNotification("An error Occurred, Please retry!", true);
  };

  displayActionButtons = () => {
    const { donationDetail } = { ...this.state };
    if ((getUserType() === "donor") & (donationDetail.status === "waiting"))
      return (
        <div className="col-md-6">
          <button
            onClick={async () =>
              await this.resendDonationReq(donationDetail.id)
            }
            className="btn-style-2 btn btn-primary pull-right mt-20 ml-10"
          >
            Resend Request
          </button>
          <CancelDonation
            donationId={donationDetail.id}
            cancelDonation={this.cancelDonation}
          />
        </div>
      );
    if (getUserType() === "charity") {
      if (donationDetail.status === "waiting")
        return this.showAcceptDonationButton();
      else
        return (
          <div className="col-md-6">
            <button
              onClick={async () => await this.receiveDonation()}
              className="btn-style-2 pull-right btn mt-20 ml-10"
            >
              Donation Received
            </button>
          </div>
        );
      // if (donationDetail.is_self_pickup)
      //   return (
      //     <div className="col-md-6">
      //       <button
      //         onClick={() => this.deleteDonation()}
      //         className="btn-style-2 btn pull-right mt-20 ml-10"
      //       >
      //         Change Pickup Type
      //       </button>
      //       <button className="btn-style-2 btn btn-primary pull-right mt-20 ml-10">
      //         Cancel Pickup
      //       </button>
      //     </div>
      //   );
    }
  };

  isExtendTimeEnable = () => {
    let enabled = false;
    const { pickup_time } = { ...this.state.donationDetail };
    const date = new Date();
    const date2 = new Date(pickup_time);
    const diffTime = Math.abs(date2 - date);
    if (diffTime <= 300000) enabled = true;
    return enabled;
  };
  render(props) {
    const { loading, donationDetail } = { ...this.state };
    return loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        {/* <ToastNotification message={"Hello"} />; */}
        <div className="row mt-20">
          <div
            className="col-md-8"
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <NavLink to="/home" className="btn-style-2 btn">
              <i className="fa fa-chevron-left"></i>
            </NavLink>
            <div
              className="title-1"
              style={{ padding: 20, display: "inline-block" }}
            >
              Donation Details
            </div>
          </div>

          {getUserType() === "donor" && (
            <div className="col-md-4">
              {donationDetail.status === "waiting" && (
                <NavLink
                  to={"/donation/edit/" + this.props?.match?.params?.id}
                  className="btn-style-2 btn pull-right mt-20 ml-10"
                >
                  <i className="fa fa-edit"></i> Edit Donation
                </NavLink>
              )}
            </div>
          )}
        </div>
        <hr className="line-1" />
        {getUserType() === "donor" && (
          <CustomAlert status={donationDetail.status} icon="send" />
        )}
        {/* {!donationDetail.is_volunteers_not_accepted &&
          getUserType() === "charity" &&
          donationDetail.status !== "waiting" && (
            <CustomAlert status={donationDetail.status} icon="send" />
          )} */}
        {!donationDetail.is_volunteers_not_accepted &&
          getUserType() === "charity" &&
          donationDetail.status !== "waiting" && (
            <CustomAlert
              volunteers_not_accepted={
                donationDetail.is_volunteers_not_accepted
              }
              status={donationDetail.status}
              icon="send"
            />
          )}
        {getUserType() === "donor" &&
          donationDetail.status === "waiting" &&
          this.isExtendTimeEnable() && (
            <div>
              <input
                style={{
                  width: "150px",
                  marginTop: "10px",
                  textAlign: "center",
                  outline: "none",
                }}
                className="mr-10"
                value={this.state.extendTimeInMinutes}
                onChange={(e) =>
                  this.setState({ extendTimeInMinutes: e.target.value })
                }
                type="number"
                placeholder="Time in minutes"
              />
              <button
                onClick={() => this.extendTime()}
                type="button"
                className="btn btn-warning btn-sm mt-0"
              >
                Extend Time
              </button>
            </div>
          )}
        {/* Start of Pick up code area coding part */}
        {this.displayPickUpCodeArea()}
        {/* End of Pick up code area */}
        <div className="row">
          <div className="grid-container-type-2 donation-details ">
            {/* Start of Donation Detail Data */}
            {this.displayDonationDetail()}
            {/* end of Donation Detail Data */}
            {this.displayFoodDetails()}
            {getUserType() === "charity" && this.displayDonatedBy()}
            {getUserType() === "donor" && this.displayDonorDesignateCharities()}
            {getUserType() === "donor" &&
              donationDetail?.status == "accepted" &&
              !donationDetail?.is_self_pickup &&
              this.displayVolunteerDetails()}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 title-1 p-10"></div>
          {!(
            donationDetail.status === "completed" ||
            donationDetail.status === "cancelled" ||
            donationDetail.status === "expired"
          ) && this.displayActionButtons()}
        </div>
        {this.state.error.status && (
          <ToastNotification message={this.state.error.message} />
        )}
      </React.Fragment>
    );
  }
}

export default DonationDetails;
