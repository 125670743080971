import React, { Component } from "react";
import Popup from "../common/popup";

class CancelDonation extends Component {
  state = {
    close: false,
    comments: null,
  };
  showPopup = () => {
    this.setState({ close: false, comments: null });
  };

  updateComments = (value) => {
    this.setState({ comments: value });
  };
  render(props) {
    const { comments, close } = { ...this.state };
    return (
      <div className="CancelDonation-container">
        <Popup
          body={
            <div>
              <p>Do you want to cancel the donation? Let us know why</p>
              <input
                type="text"
                className="form-control"
                onChange={(e) => this.updateComments(e.target.value)}
                value={comments}
                placeholder="Enter your comments"
              />
              <div className="mt-20">
                <button
                  onClick={() => this.setState({ close: !close })}
                  className="btn-style-2 btn mr-20"
                >
                  Cancel
                </button>
                <button
                  onClick={() =>
                    this.props.cancelDonation(this.props.donationId, comments)
                  }
                  className="btn-style-2 btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </div>
          }
          close={close}
          buttonTrigger={
            <button
              onClick={this.showPopup}
              className="btn-style-2 btn pull-right mt-20 ml-10"
            >
              Cancel Donation
            </button>
          }
        />
      </div>
    );
  }
}

export default CancelDonation;
