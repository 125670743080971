import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./home/home";
import Sidebar from "./sidebar/sidebar";
import Footer from "./footer/footer";
import Donation from "./donation";
import DonationHistory from "./donationHistory/donationHistory";
import Settings from "./settings/settings";
import Faq from "./faq/faq";
import AddDonation from "./addDonation/AddDonation";
import DonationDetails from "./DonationDetails";
import DonationSuccess from "./DonationSuccess";
import Logout from "./logout";
import Profile from "./profile/Profile";
import { getUserType } from "../services/userServices";
import VolunteerDonationDetail from "./DonationDetail/VolunteerDonationDetail/VolunteerDonationDetail";
import AboutWnf from "./aboutwnf/AboutWnf";
import Test from "./faq/faq2";
import ToastNotification from "./common/toastNotification";

class ControlPanel extends Component {
  state = {
    NotificationMessage: null,
    redirect: false,
  };

  componentDidMount = () => {
    this.setState({ NotificationMessage: null, redirect: false });
  };

  updateNotificationMessage = (message, redirect = false) => {
    this.setState({ NotificationMessage: message, redirect });
  };

  render() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    return (
      <React.Fragment>
        <div className="container">
          <ToastNotification
            message={this.state.NotificationMessage}
            redirect={this.state.redirect}
          />
          <div className="row">
            <div className="col-lg-3 sidebar">
              <Sidebar />
            </div>
            <div className="col-lg-9  page-content">
              <Switch>
                <Route
                  path="/home/add-donation"
                  render={(props) => (
                    <AddDonation
                      {...props}
                      sendNotification={this.updateNotificationMessage}
                    />
                  )}
                />
                <Route path="/home" component={Home} />
                <Route
                  path="/profile"
                  render={(props) => (
                    <Profile
                      {...props}
                      sendNotification={this.updateNotificationMessage}
                    />
                  )}
                />
                <Route
                  path="/donation/edit/:id"
                  render={(props) => (
                    <AddDonation
                      {...props}
                      edit={true}
                      sendNotification={this.updateNotificationMessage}
                    />
                  )}
                />
                <Route
                  path="/donation/details/:id"
                  render={(props) =>
                    getUserType() === "volunteer" ? (
                      <VolunteerDonationDetail
                        {...props}
                        sendNotification={this.updateNotificationMessage}
                      />
                    ) : (
                      <DonationDetails
                        {...props}
                        sendNotification={this.updateNotificationMessage}
                      />
                    )
                  }
                />

                <Route path="/donation" component={Donation} />
                <Route path="/donation-history" component={DonationHistory} />
                <Route
                  path="/settings"
                  render={(props) => (
                    <Settings
                      {...props}
                      sendNotification={this.updateNotificationMessage}
                    />
                  )}
                />
                <Route path="/faq" component={Faq} />
                <Route path="/test" component={Test} />
                <Route path="/about" component={AboutWnf} />
                <Route path="/donation-success" component={DonationSuccess} />
                <Route path="/logout" component={Logout} />
                <Redirect from="/" to="/home" />
              </Switch>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default ControlPanel;
