import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import CustomAlert from "../common/customAlert";
import { apiUrl } from "../../config.json";
import "./activeDonation.scss";
import * as donationService from "../../services/donationServices";
import DonationDetails from "../DonationDetails";
import getDateString from "../common/commonFunction";
import icons from "../common/icons";
import Loader from "../common/loader";
import { getUserType } from "../../services/userServices";
import CharityActiveDonation from "../CharityActiveDonation/CharityActiveDonation";
import VolunteerActiveDonation from "../volunteerActiveDonations/volunteerActiveDonation";
import VolunteerAcceptedDonations from "../VolunteerAcceptedDonations/VolunteerAcceptedDonations";

const { share, clock, send, instruction } = icons;
class ActiveDonation extends Component {
  state = {
    loading: true,
    data: null,
  };
  apiEndPoint = apiUrl + "/donations";

  async componentDidMount() {
    const donations = await donationService.getActiveDonations();
    this.setState({ data: donations, loading: false });
  }

  getActiveDonationsForDonor = () => {
    const { data, loading } = { ...this.state };
    return loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        <div className="activeDonationBox">
          {data &&
            data.map((donation, key) => {
              return (
                <div key={key} className="donation-container">
                  <NavLink
                    to={{
                      pathname: "/donation/details/" + donation?.id,
                      donationData: donation,
                    }}
                  >
                    <div className="box-style-2 p0">
                      <div className="section1">
                        <div className="container">
                          <div className="row donation-header p-10">
                            <div className="left food-description">
                              <p>{donation.food_description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="line-2" />
                      <div className="section2">
                        <div className="left col-md-12">
                          <p className="text-muted mb-2">
                            <img src={clock} alt={clock} /> Pickup Date & Time
                          </p>
                          <p className="normal-text">
                            {donation.pickup_time &&
                              getDateString(donation.pickup_time)}
                          </p>
                        </div>
                      </div>
                      <hr className="line-2" />
                      <div className="section2">
                        <div className="left col-md-12">
                          <p className="text-muted mb-2">
                            <img src={send} alt={send} /> Pickup Location
                          </p>
                          <p className="normal-text" style={{ minHeight: 50 }}>
                            {donation.pickup_location}
                          </p>
                        </div>
                      </div>
                      <hr className="line-2" />
                      <div className="section2">
                        <div className="left col-md-12">
                          <p className="text-muted mb-2">
                            <img src={instruction} alt={instruction} /> Specific
                            Instructions
                          </p>
                          <p className="normal-text" style={{ minHeight: 30 }}>
                            {donation.pickup_instruction}
                          </p>
                        </div>
                      </div>
                      <CustomAlert
                        status={donation.status}
                        icon="clock-o"
                        font="14px"
                      />
                    </div>
                  </NavLink>
                </div>
              );
            })}
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        {getUserType() === "donor" && this.getActiveDonationsForDonor()}
        {getUserType() === "charity" && <CharityActiveDonation />}
        {getUserType() === "volunteer" && <VolunteerAcceptedDonations />}
      </React.Fragment>
    );
  }
}

export default ActiveDonation;
