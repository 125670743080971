import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import * as UserService from "../../services/userServices";
import "./sidebar.scss";

class Sidebar extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="sidebar-style-1 hidden-xs">
          <ul className="type3">
            {UserService.getUserType() == "donor" && (
              <React.Fragment>
                <li>
                  <NavLink to="/home">
                    <i className="fa fa-home"></i>Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/donation">
                    <i className="fa fa-check-square"></i>Active Donations
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/donation-history">
                    <i className="fa fa-history"></i>Donation History
                  </NavLink>
                </li>
              </React.Fragment>
            )}
            {UserService.getUserType() == "charity" && (
              <React.Fragment>
                <li>
                  <NavLink to="/home">
                    <i className="fa fa-home"></i>Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/donation">
                    <i className="fa fa-check-square"></i>Accepted Donations
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/donation-history">
                    <i className="fa fa-history"></i>Donation History
                  </NavLink>
                </li>
              </React.Fragment>
            )}
            {UserService.getUserType() == "volunteer" && (
              <React.Fragment>
                <li>
                  <NavLink to="/home">
                    <i className="fa fa-home"></i>Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/donation">
                    <i className="fa fa-check-square"></i>Accepted Donations
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/donation-history">
                    <i className="fa fa-history"></i>Donation History
                  </NavLink>
                </li>
              </React.Fragment>
            )}
            <li>
              <NavLink to="/settings">
                <i className="fa fa-cog"></i>Settings
              </NavLink>
            </li>
            <li>
              <NavLink to="/about">
                <i className="fa fa-magic"></i>About WNF
              </NavLink>
            </li>
            <li>
              <NavLink to="/faq">
                <i className="fa fa-question-circle"></i>FAQs
              </NavLink>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default Sidebar;
