import React, { Component } from "react";
import ActiveDonation from "./activeDonation/activeDonation";
import { getUserType } from "../services/userServices";
import { NavLink } from "react-router-dom";

class Donation extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row mt-20">
          <div className="col-md-6">
            {getUserType() === "donor" && (
              <div className="title-1 p-10">Active Donations</div>
            )}
            {(getUserType() === "charity" || getUserType() === "volunteer") && (
              <div className="title-1 p-10">Accepted Donations</div>
            )}
          </div>
          {getUserType() === "donor" && (
            <div className="col-md-6">
              <NavLink
                to="/home/add-donation"
                className="btn-style-1 pull-right"
              >
                Create New Donation
              </NavLink>
            </div>
          )}
        </div>
        <hr className="line-1" />
        <ActiveDonation />
      </React.Fragment>
    );
  }
}

export default Donation;
