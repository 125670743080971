import _ from "lodash";
import http from "../services/httpService";
import { apiUrl, imageURL } from "../config.json";
import { getUserType } from "./userServices";
import icons from "../components/common/icons";
import { getDateTime, setDateTime } from "../components/common/commonFunction";
import axios from "axios";
const userDetails = JSON.parse(localStorage.getItem("userDetails"));
const donor_id = userDetails?.donor_id;
const user_id = userDetails?.user_id;

export const donationReceived = async (id) => {
  const apiEndpoint = apiUrl + "/donations/" + id;
  const req = {
    status: "completed",
    received_by: userDetails.user_id,
    received_at: new Date(),
    delivered_at: new Date(),
  };
  const { data: response } = await http.put(apiEndpoint, req);
  return response;
};

export const getDonationData = async (id) => {
  const donorApiEndpoint = apiUrl + "/donors/donations/" + id;
  console.log("donorApiEndpoint", donorApiEndpoint);
  const charityApiEndpoint = apiUrl + "/charities/donations/" + id;
  const { data: donorDonationData } = await http.get(donorApiEndpoint);
  let donationData = donorDonationData;
  if (getUserType() === "donor") donationData = donorDonationData;
  if (
    getUserType() === "charity" &&
    donorDonationData?.data.status !== "waiting"
  ) {
    let { data } = await http.get(charityApiEndpoint);
    donationData = data;
  }
  return donationData?.data;
};

export const exportDonationData = async () => {
  const apiEndPoint = apiUrl + "/donations/export/data";
  let userId;
  if (getUserType() === "donor") userId = "donor_id";
  if (getUserType() === "charity") userId = "charity_id";
  if (getUserType() === "volunteer") userId = "volunteer_id";
  const req = {
    [userId]: userDetails[userId],
    email: userDetails?.email,
  };
  const { data: response } = await http.post(apiEndPoint, req);
  return response;
};

const getFoodDescription = (foodDetails) => {
  const description = [];
  foodDetails.forEach((food) =>
    description.push(food.quantity + " " + food.addFood)
  );
  return description.join(", ");
};

const getFoodItems = (foodDetails) => {
  const foodItems = [];
  foodDetails.forEach((food) =>
    foodItems.push({
      name: food.addFood,
      quantity: food.quantity,
      picture: food.addPhoto,
    })
  );
  return foodItems;
};

const createDonation = async (donationObj, foodDetails, charities) => {
  const description = getFoodDescription(foodDetails);
  const foodItems = getFoodItems(foodDetails);
  const dateTime = setDateTime({
    date: donationObj.date,
    time: donationObj.time,
  });
  const donation = {
    description: description,
    donor_id: donor_id,
    pickup_time: dateTime,
    address: donationObj.pickUpLocation,
    location: donationObj.pickUpLocation,
    instructions: donationObj.specificInstruction,
    charities: charities,
    user_id: user_id,
    donation_value: donationObj.donationValue,
    food_items: foodItems,
    timezone: new Date().getTimezoneOffset(),
  };
  const apiEndPoint = apiUrl + "/donations";

  const response = await http.post(apiEndPoint, donation);
  return response.status;
};

const editDonation = async (id, donationObj, foodDetails, charities) => {
  const description = getFoodDescription(foodDetails);
  const foodItems = getFoodItems(foodDetails);
  const dateTime = setDateTime({
    date: donationObj.date,
    time: donationObj.time,
  });
  const donation = {
    id: id,
    description: description,
    food_description: description,
    donor_id: donor_id,
    pickup_time: dateTime,
    address: donationObj.pickUpLocation,
    pickup_location: donationObj.pickUpLocation,
    pickup_instruction: donationObj.specificInstruction,
    charities: charities,
    user_id: user_id,
    approximate_value: donationObj.donationValue,
    food_items: foodItems,
    timezone: new Date().getTimezoneOffset(),
  };
  const apiEndPoint = apiUrl + "/donations/info/" + id;
  const response = await http.post(apiEndPoint, donation);
  return response.status;
};

export const cancelDonation = async (id, comments) => {
  const apiEndPoint = apiUrl + "/donations/" + id;
  var req = {
    is_cancelled: true,
    cancelled_reason: comments,
    cancelled_by: userDetails.user_id,
    cancelled_at: new Date(),
    status: "waiting",
  };
  const { data: response } = await http.put(apiEndPoint, req);
  return response.status;
};

export const resendRequest = async (id) => {
  const apiEndPoint = apiUrl + "/donations/resend";
  const { data: response } = await http.post(apiEndPoint, { donationId: id });
  return response.status;
};

const confirmDonation = async (donationId, pickupCode) => {
  const apiEndPoint = apiUrl + "/donations/confirm";
  const response = await http.post(apiEndPoint, {
    donationId: donationId,
    code: pickupCode,
  });
  return response;
};

const extendTime = async (donationId, time) => {
  const apiEndPoint = apiUrl + "/donations/extend-time/" + donationId;
  let req = time ? { time } : {};
  const response = await http.put(apiEndPoint, req);
  return response.data;
};

export const getDonorDonationsHistory = async () => {
  const apiEndPoint = apiUrl + "/donors/donations";
  const { data } = await http.post(apiEndPoint, {
    donor_id: userDetails?.donor_id,
    active: false,
  });
  return data?.data;
};

export const getActiveDonations = async () => {
  if (getUserType() === "donor") return await getDonorActiveDonations();
  if (getUserType() === "charity") return await getCharityActiveDonations();
};

export const getDonorActiveDonations = async () => {
  const apiEndPoint = apiUrl + "/donors/donations";
  const { data } = await http.post(apiEndPoint, {
    donor_id: userDetails?.donor_id,
  });
  return data?.data;
};

export const AcceptRequest = async (donationId) => {
  const apiEndPoint = apiUrl + "/donations/" + donationId;
  const req = {
    volunteer_id: userDetails?.volunteer_id,
    status: "accepted",
    accepted_at: new Date(),
  };
  const { data: response } = await http.put(apiEndPoint, req);
  return response;
};

export const CancelPickup = async (donationId, Comments) => {
  const apiEndPoint = apiUrl + "/donations/" + donationId;
  const req = {
    is_cancelled: true,
    cancelled_reason: Comments,
    cancelled_by: userDetails?.user_id,
    cancelled_at: new Date(),
    status: "cancelled",
  };
  const { data: response } = await http.put(apiEndPoint, req);
  return response;
};

export const DeliverDonation = async (donationId) => {
  const apiEndPoint = apiUrl + "/donations/" + donationId;

  const { data: response } = await http.put(apiEndPoint, {
    delivered_at: new Date(),
    status: "delivered",
  });
  return response;
};

export const getCharityDonationsHistory = async () => {
  const apiEndPoint = apiUrl + "/charities/donations";
  const { data } = await http.post(apiEndPoint, {
    charity_id: userDetails?.charity_id,
    active: false,
  });
  return data?.data;
};
export const getCharityActiveDonations = async () => {
  const apiEndPoint = apiUrl + "/charities/donations";
  const { data } = await http.post(apiEndPoint, {
    charity_id: userDetails?.charity_id,
  });
  return data?.data;
};

export const getActiveDonationsByMiles = async (miles) => {
  const apiEndPoint = apiUrl + "/charities/donations/active";
  const { data } = await http.post(apiEndPoint, {
    charity_id: userDetails?.charity_id,
    miles: miles,
  });
  return data?.data;
};

export const getAllDonationsForVolunteer = async (miles) => {
  const apiEndPoint = apiUrl + "/volunteers/donations/active";
  const req = {
    volunteer_id: userDetails?.volunteer_id,
    miles: miles,
  };
  const { data } = await http.post(apiEndPoint, req);
  return data?.data;
};

export const getDonationsHistoryForVolunteer = async () => {
  const apiEndPoint = apiUrl + "/volunteers/donations/";
  const { data } = await http.post(apiEndPoint, {
    volunteer_id: userDetails?.volunteer_id,
    active: false,
  });
  return data?.data;
};

export const getDonationInfoForVolunteer = async (donationId) => {
  const apiEndPoint =
    apiUrl + "/volunteers/donations/" + donationId + "/" + userDetails?.user_id;
  const { data } = await http.get(apiEndPoint);
  return data?.data;
};

export const getAcceptedDonationsForVolunteer = async () => {
  const apiEndPoint = apiUrl + "/volunteers/donations/";
  const { data } = await http.post(apiEndPoint, {
    volunteer_id: userDetails?.volunteer_id,
  });
  return data;
};

export const AcceptDonation = async (id, is_self_pickup) => {
  const req = {
    status: "reserved",
    reserved_by: userDetails.user_id,
    reserve: true,
    reserved_at: new Date(),
    charity_id: userDetails.charity_id,
    is_self_pickup: is_self_pickup,
  };
  const apiEndPoint = apiUrl + "/donations/" + id;
  const { data } = await http.put(apiEndPoint, req);
  return data?.status;
};

export const getEditDonationDetails = async (id) => {
  let apiEndpoint;
  if (getUserType() === "donor")
    apiEndpoint = apiUrl + "/donors/donations/" + id;

  let { data: donation } = await http.get(apiEndpoint);

  donation = donation?.data;
  const dateTime = getDateTime(donation.pickup_time);
  const DonationData = {
    pickUpLocation: donation.pickup_location,
    date: dateTime.date ? dateTime.date : null,
    time: dateTime.time ? dateTime.time : null,
    donationValue: donation.approximate_value,
    specificInstruction: donation.pickup_instruction,
    designateCharities: false,
  };
  let designated_charities = donation.designated_charities;
  let FoodItems = [...donation.food_items];

  FoodItems = [
    ...donation.food_items.map((food) => {
      return _.pick(food, ["foodname", "quantity", "picture"]);
    }),
  ];

  FoodItems = FoodItems.map((food) => {
    food["addFood"] = food["foodname"]; // Assign new key
    food["addPhoto"] =
      food["picture"] !== "null" ? food["picture"] : icons.defaultFoodIcon; // Assign new key
    delete food["foodname"]; // Delete old key
    delete food["picture"]; // Delete old key
    return food;
  });
  return {
    createDonationData: DonationData,
    foodItems: FoodItems,
    designateCharitiesData: [...donation.designated_charities],
  };
};

export const uploadHandler = async (value) => {
  const apiEndPoint = apiUrl + "/upload";
  const data = new FormData();
  data.append("photo", value);
  return await axios.post(apiEndPoint, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    progress: (progressEvents) => {},
    onUploadProgress: (progressEvent) => {},
  });
};

export default {
  createDonation: createDonation,
  confirmDonation: confirmDonation,
  AcceptDonation: AcceptDonation,
  cancelDonation: cancelDonation,
  resendRequest: resendRequest,
  getEditDonationDetails: getEditDonationDetails,
  editDonation: editDonation,
  getAllDonationsForVolunteer,
  getAcceptedDonationsForVolunteer,
  getDonationsHistoryForVolunteer,
  getDonationInfoForVolunteer,
  DeliverDonation,
  CancelPickup,
  AcceptRequest,
  uploadHandler,
  exportDonationData,
  getDonationData,
  donationReceived,
  extendTime,
};
