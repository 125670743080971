import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Joi, { create } from "joi-browser";
import { Form, Button } from "react-bootstrap";
import Loader from "../common/loader";
import Popup from "../common/popup";
import AddItemPopup from "../AddItemPopUp/AddItemPopup";
import { imageURL } from "../../config.json";
import icons from "../common/icons";
import { validate } from "../../validators/joiValidator";
import http from "../../services/httpService";
import donationService from "../../services/donationServices";
import * as userServices from "../../services/userServices";
import "./addDonation.scss";

import * as commonfuncs from "../common/commonFunction";
import EditItemPopup from "../EditItemPopup/EditItemPopup";

class AddDonation extends Component {
  state = {
    loading: true,
    foodItems: [],
    designateCharitiesMasterData: [],
    data: {
      createDonationData: {
        pickUpLocation: "",
        date: null,
        time: null,
        donationValue: "",
        specificInstruction: "",
        designateCharities: false,
      },
    },
    errors: {
      createDonationErrors: {
        pickUpLocation: undefined,
        date: undefined,
        time: undefined,
        donationValue: undefined,
        specificInstruction: undefined,
        designateCharities: undefined,
      },
    },
  };

  AddItemSchema = {
    addFood: Joi.string().required().label("Food"),
    quantity: Joi.number().min(1).required().label("Quantity"),
    addPhoto: Joi.required().allow(null, ""),
    id: Joi.number().allow(null, ""),
  };

  createDonationSchema = {
    pickUpLocation: Joi.string().min(3).required().label("Pick up Location"),
    date: Joi.string().required().label("Date"),
    time: Joi.string().required().label("Time"),
    donationValue: Joi.number().min(1).required().label("Donation Value"),
    designateCharities: Joi.boolean(),
    specificInstruction: Joi.string().allow(null, ""),
  };

  donationId = this.props?.match?.params?.id;
  editable = this.props?.edit;

  componentDidMount = async () => {
    const { data } = { ...this.state };
    let charities = await userServices.getAllDesignatedCharityUsers();
    // IF Edit Donation Part is enabled we update createDonationData and Charities data
    if (this.editable) {
      let {
        createDonationData,
        designateCharitiesData,
        foodItems,
      } = await donationService.getEditDonationDetails(this.donationId);
      let id = 0;
      foodItems.map((food) => {
        food["id"] = id + 1;
        id += 1;
      });

      data.createDonationData = createDonationData;
      charities = charities.data;
      let CharityIds = [];
      for (let charitySelected of designateCharitiesData)
        CharityIds.push(charitySelected.charity_id);

      if (charities.length > 0) {
        charities.map((charity) => {
          if (CharityIds.includes(charity.charity_id))
            charity["selected"] = true;
          else charity["selected"] = false;
        });
      }
      this.setState({
        data,
        designateCharitiesMasterData: charities,
        foodItems,
        loading: false,
      });
    }
    //If add donation part is enabled.
    else {
      const { data } = { ...this.state };
      data.createDonationData.pickUpLocation = userServices.getUserAddress();
      charities = charities.data;
      for (let charity of charities) charity["selected"] = false;
      this.getDateTime(); //to set the current date and time in the add donation
      this.setState({
        designateCharitiesMasterData: charities,
        loading: false,
        data,
      });
    }
  };

  addFoodItem = (e, addItemdata) => {
    e.preventDefault();
    let foods = [...this.state.foodItems];
    addItemdata["id"] = foods.length + 1;
    foods.push({ ...addItemdata });
    this.setState({ foodItems: foods });
  };

  editFoodItem = (e, item) => {
    e.preventDefault();
    const { foodItems } = { ...this.state };
    const editedItem = foodItems.filter((food) => food.id === item.id);
    editedItem[0]["addFood"] = item.addFood;
    editedItem[0]["quantity"] = item.quantity;
    editedItem[0]["addPhoto"] = item.addPhoto;
    this.setState({ foodItems });
  };

  handleDeleteFood = (e, foodId) => {
    e.preventDefault();
    const foods = [...this.state.foodItems];
    const foodItems = foods.filter((item) => item.id !== foodId);
    this.setState({ foodItems });
  };

  onChangeCreateDonation = async (inputValue, inputField) => {
    const { data, errors, designateCharitiesMasterData } = { ...this.state };
    const { createDonationData } = data;
    const { createDonationErrors } = errors;
    var charities = designateCharitiesMasterData;

    if (inputField === "designateCharities") {
      createDonationData[inputField] = !createDonationData[inputField];
      //if (!createDonationData[inputField]) {
        //if switch is in off state
        //charities.forEach((charity) => (charity.selected = false));
     // }
    } else createDonationData[inputField] = inputValue;

    

    const validateData = {
      [inputField]: createDonationData[inputField],
    };
    const validateSchema = {
      [inputField]: this.createDonationSchema[inputField]
        ? this.createDonationSchema[inputField]
        : undefined,
    };
    const error = validateSchema[inputField]
      ? validate(validateSchema, validateData)
      : [];
    createDonationErrors[inputField] = error[0];

    const { createDonationData: switchValue } = { ...this.state.data };
    data["createDonationData"] = createDonationData;
    errors["createDonationErrors"] = createDonationErrors;
    this.setState({ data, errors, designateCharitiesMasterData: charities });
    
  };
  onPickUpLocationChange = async (inputValue, inputField) => {
    if(inputField === "pickUpLocation"){
      let charities = await userServices.getAllDesignatedCharityUsers(inputValue);
      charities = charities.data;
      for (let charity of charities) charity["selected"] = false;
      this.setState({
        designateCharitiesMasterData: charities
      });
    }
  }

  displayFoodItems = () => {
    const foodItems = [...this.state.foodItems];
    return (
      foodItems.length > 0 &&
      foodItems.map((item) => {
        return (
          <div key={item.id} className="media mt-20">
            {item.addPhoto ? (
              <img
                style={{ height: "40px", width: "40px" }}
                className="mr-2"
                src={
                  item.addPhoto
                    ? imageURL + item.addPhoto
                    : icons.defaultFoodIcon
                }
              />
            ) : (
              <img
                className="mr-2 plus"
                src={icons.defaultFoodIcon}
                alt="food"
              />
            )}

            <div className="media-body row">
              <div className="col-8">
                <h5 className="mt-0 mb-0">{item.addFood}</h5>
                Qty: {item.quantity}
              </div>
              <div className="col-4">
                <i
                  onClick={(e) => this.handleDeleteFood(e, item.id)}
                  className="fa fa-trash mt-3 pull-right cursor"
                ></i>
                <EditItemPopup
                  food={item}
                  editItemSchema={this.AddItemSchema}
                  editFoodItem={this.editFoodItem}
                />
              </div>
            </div>
          </div>
        );
      })
    );
  };

  handleAddCharities = (key) => {
    const charities = [...this.state.designateCharitiesMasterData];
    const selectedCharity = charities[key];
    selectedCharity.selected = !selectedCharity.selected;
    this.setState({ designateCharitiesMasterData: charities });
  };

  handleDesignateCharitiesPopUp = () => {
    // returns the list of all charities in HTML format
    const charities = [...this.state.designateCharitiesMasterData];
    return charities.map((charity, key) => (
      <div key={key} className="media mt-3 charity-popup">
        <input
          className="mr-2 mt-3"
          onClick={() => this.handleAddCharities(key)}
          type="checkbox"
          value={charity.charity_name}
          checked={charity.selected}
        />
        <img
          className="mr-2 icon"
          src={
            charity.picture && charity.picture !== "null"
              ? imageURL + charity.picture
              : icons.defaultProfileIcon
          }
          alt="Generic placeholder image"
        />
        <div className="media-body row">
          <div className="col-8">
            <span style={{ display: "block" }} className="mt-0 mb-0">
              {charity.charity_name}
            </span>
            <span
              className="text-muted"
              style={{ fontSize: 12, display: "block" }}
            >
              {charity.distance + " Miles"}
            </span>
          </div>
        </div>
      </div>
    ));
  };

  showSelectedCharities = () => {
    // returns the list of all charities in HTML format
    const charities = [
      ...this.state.designateCharitiesMasterData.filter(
        (charity) => charity.selected
      ),
    ];
    return charities.map((charity, key) => (
      <React.Fragment key={key}>
        <div className="media mt-3">
          <img
            className="mr-2 icon"
            src={
              charity.picture && charity.picture !== "null"
                ? imageURL + charity.picture
                : icons.defaultProfileIcon
            }
            alt={charity.charity_name}
          />
          <div className="media-body row">
            <div className="col-8">
              <span className="mt-0 mb-0">{charity.charity_name}</span>
              <br />
              <span
                style={{ display: "block", fontSize: 12 }}
                className="text-muted"
              >
                {charity.distance + " Miles"}
              </span>
            </div>
          </div>
        </div>
        <hr className="line-2" />
      </React.Fragment>
    ));
  };

  customValidation = () => {
    const errors = [];
    if (this.state.foodItems.length === 0)
      errors.push("  Please add atleast one Food Item to continue.");
    return errors;
  };

  getformErrors = () => {
    const { createDonationData } = { ...this.state.data };
    const createDonationErrors = validate(
      this.createDonationSchema,
      createDonationData
    );
    let errors = [...createDonationErrors];
    const customErrors = this.customValidation();
    if (customErrors.length > 0) errors = errors.concat(customErrors);
    return errors;
  };

  showErrorsInPopUp = () => {
    return (
      <Popup
        title="Errors"
        body={
          this.getformErrors().length > 0 &&
          this.getformErrors().map((error) => <li key={error}>{error}</li>)
        }
        buttonTrigger={
          <button className="btn-style-1">
            {this.editable ? "Update" : "Submit"}
          </button>
        }
      />
    );
  };

  getDateTime = () => {
    const data = { ...this.state.data };
    const currentDate = new Date();
    var minutesToAdd = 30;
    var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
    const date =
      futureDate.getFullYear() +
      "-" +
      ("0" + (futureDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + futureDate.getDate()).slice(-2);
    const time =
      ("0" + futureDate.getHours()).slice(-2) +
      ":" +
      ("0" + futureDate.getMinutes()).slice(-2);
    data.createDonationData.date = date;
    data.createDonationData.time = time;
    this.setState({ data });
  };

  getSelectedCharities = () => {
    let charities = [...this.state.designateCharitiesMasterData];
    charities = charities.filter((charity) => charity.selected === true);
    let charity = [];
    charities.forEach((char) => charity.push(char.charity_id));
    return charity;
  };

  findCharitySelected = () =>{
    let charities = [...this.state.designateCharitiesMasterData];
    charities = charities.filter((charity) => charity.selected);
    return charities;
  }
  SubmitDonation = async () => {
    const { createDonationData } = { ...this.state.data };
    const charities = this.getSelectedCharities();
    this.setState({ loading: true });
    let response = undefined;
    if (this.editable) {
      response = await donationService.editDonation(
        this.donationId,
        createDonationData,
        [...this.state.foodItems],
        charities
      );
    } else {
      response = await donationService.createDonation(
        createDonationData,
        [...this.state.foodItems],
        charities
      );
    }
    if (response === 200) {
      this.props.sendNotification("Donation Submitted Successfully");
      return this.props.history.push("/home");
    } else
      this.props.sendNotification("An Error Occurred. Please retry!", true);
  };

  render() {
    const { data, errors } = { ...this.state };
    const { createDonationErrors } = errors;
    const { createDonationData } = data;
    return this.state.loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <div className="title-1 row mt-20 mb-20">
              <div className="col-1">
                <NavLink to="/home" className="btn-style-2 btn mt-10">
                  <i className="fa fa-chevron-left"></i>
                </NavLink>
              </div>
              <div className="col-11 mt-10">
                {this.editable ? (
                  <React.Fragment>Edit Donation</React.Fragment>
                ) : (
                  <React.Fragment>Create New Donation</React.Fragment>
                )}
              </div>
            </div>
          </div>
          <hr className="line-1" />
        </div>
        <div className="row" id="add-donation">
          <div className="col-md-12 donation-box p-10">
            {/* TO display list of food items added in the popup */}
            {this.displayFoodItems()}
            <div className="row mt-2">
              {/* TO display popup from where food items can be added */}
              {/* <label className="form-label ml-2" for="formBasicPassword"> Add Food Items</label> */}
              <AddItemPopup
                addFoodItem={this.addFoodItem}
                AddItemSchema={this.AddItemSchema}
              />
            </div>
          </div>
          <div className="col-md-12 donation-box mt-20 p-10">
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Pickup Location</Form.Label>
              <Form.Control
                type="text"
                value={createDonationData.pickUpLocation}
                placeholder="576 East Gilbert Street, San Jose, CA"
                onChange={(e) =>
                  this.onChangeCreateDonation(e.target.value, "pickUpLocation")
                }
                onBlur={(e) =>
                  this.onPickUpLocationChange(e.target.value, "pickUpLocation")
                }
              />
              {createDonationErrors.pickUpLocation && (
                <div className="alert alert-danger mt-10 ">
                  {createDonationErrors.pickUpLocation}
                </div>
              )}
            </Form.Group>
          </div>
          <div className="donation-box col-md-12 mt-20 p-10">
            <div className="row">
              <div className="col-6">
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    value={createDonationData.date}
                    type="date"
                    onChange={(e) =>
                      this.onChangeCreateDonation(e.target.value, "date")
                    }
                  />
                  {createDonationErrors.date && (
                    <div className="alert alert-danger mt-10 ">
                      {createDonationErrors.date}
                    </div>
                  )}
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Time</Form.Label>
                  <Form.Control
                    type="time"
                    value={createDonationData.time}
                    onChange={(e) =>
                      this.onChangeCreateDonation(e.target.value, "time")
                    }
                  />
                  {createDonationErrors.time && (
                    <div className="alert alert-danger mt-10 ">
                      {createDonationErrors.time}
                    </div>
                  )}
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="col-md-12 donation-box mt-20 p-10">
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Donation Value</Form.Label>
              <span class="input-symbol-dollar">
                <Form.Control
                  type="text"
                  value={createDonationData.donationValue}
                  onChange={(e) =>
                    this.onChangeCreateDonation(e.target.value, "donationValue")
                  }
                />
              </span>
              {createDonationErrors.donationValue && (
                <div className="alert alert-danger mt-10 ">
                  {this.state.errors.createDonationErrors.donationValue}
                </div>
              )}
            </Form.Group>
          </div>
          <div className="col-md-12 donation-box mt-20 p-10">
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Specific Instructions</Form.Label>
              <Form.Control
                type="text"
                value={createDonationData.specificInstruction}
                onChange={(e) =>
                  this.onChangeCreateDonation(
                    e.target.value,
                    "specificInstruction"
                  )
                }
              />
              {createDonationErrors.specificInstruction && (
                <div className="alert alert-danger mt-10 ">
                  {createDonationErrors.specificInstruction}
                </div>
              )}
            </Form.Group>
          </div>
          
          {/* designated Charity popup start */}
          <div className="col-md-12 donation-box mt-20 p-10">
            <Popup
            title="Designate Charities" 
             setHeight="380px" 
             body={ 
               <React.Fragment> 
                 {this.handleDesignateCharitiesPopUp()} 
               </React.Fragment>
             } 
            buttonTrigger={
              <div>
            <div className="row">
              <div style={{padding:"0px"}} className="col-10">
                <Form.Label >Designate Charities</Form.Label>
              </div>
              <div className="col-2">
                <Form.Check
                 checked = {this.findCharitySelected()[0]?true:false}
                  className="pull-right"
                  type="switch"
                  id="custom-switch"
                  value={createDonationData.designateCharities}
                  onChange={(e) =>
                    this.onChangeCreateDonation(
                     e.target.value,
                      "designateCharities"
                    )
                  }
                />
              </div>
            </div>
            {this.showSelectedCharities()}
          </div>}
           /> 
           </div>
                {/* designated Charity popup end */}
          <div className="col-12 mt-20 mb-20">
            <div className="float-right">
              <button
                onClick={() => this.props.history.push("/home")}
                type="reset"
                className="btn-style-3 mr-3"
              >
                Cancel
              </button>
              {/* Rendering the Submit button based on errors, to open either POpup or to move to home page */}
              {this.getformErrors().length === 0 ? (
                <button
                  onClick={() => this.SubmitDonation()}
                  className="btn-style-1 ml-1"
                >
                  {this.editable ? "Update" : "Submit"}
                </button>
              ) : (
                <div className="float-right"> {this.showErrorsInPopUp()} </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddDonation;
