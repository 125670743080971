import React, { Component } from 'react';

class ErrorBoundaries extends Component {
    state = { hasError:false }

    static getDerivedStateFromError(error) {
         return <h1>Error Occurred</h1>
      }
    componentDidCatch(error,errorInfo){
        this.setState({hasError:true})
    }
    render() { 
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
          }
          if(!this.state.hasError)
           return this.props.children; 
    }
}
 
export default ErrorBoundaries;
